import React from 'react'

export const CheckBox = props => {
    return (
      <div>
       <label className="me-3"><input key={props.id} onChange={props.handleInputChecked} type="checkbox" checked={props.isChecked} value={props.value} id={props.fieldId} disabled={props.isDisable} className="me-2"/> {props.label}</label>
      </div>
    )
}


export default CheckBox