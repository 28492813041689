// import React, { useEffect, useState } from "react";
import React from "react";
// import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
// import Guest from "./guest/Index";
// import Member from "./member/Index";
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './css/styles.css';
import './css/ct-style-2.css';
import './config/global';
// import CMS from  "./cms/Index";
import MenuGuest from "./inc/MenuBf";
import MenuMember from "./inc/MenuAf";
import Announcement from "./inc/Announcement";
import Footer from './inc/Footer';

import Login from './guest/Login';
import HomeGuest from './guest/Home';
import CourseBrowseGuest from "./guest/CourseBrowse";
import CourseDetailGuest from "./guest/CourseDetail";
import LibraryBrowseGuest from "./guest/LibraryBrowse";
import LibraryDetailGuest from "./guest/LibraryDetail";

import HomeMember from "./member/Home";
import CourseBrowseMember from './member/CourseBrowse';
import CourseDetailMember from './member/CourseDetail';
import LibraryBrowseMember from './member/LibraryBrowse';
import LibraryDetailMember from './member/LibraryDetail';
import Subscribe from "./member/Subscribe";
// import LibraryEnroll from "./member/LibraryEnroll";

import OrderDetails from './member/OrderDetail';
// import InvoiceSubscription from './member/InvoiceSubscription';
// import InvoiceCourse from './member/InvoiceCourse';
import Checkout from './member/Checkout';
import Account from './member/Account';
import Setting from './member/Settings';
import ShoppingCart from './member/ShoppingCart';

import Certificate from './Certificate';
// import Invoice from './Invoice';
// import Success from './Success';

import About from './About';
import Help from './Help';
import PrivacyPolicy from './PrivacyPolicy';
import Error403 from './Error403';
import Error404 from './Error404';
import Error204 from './Error204';

import CTDashboard from './cms/ct-homepage';
import CTCategory from './cms/ct-category-list';
import CTLibrary from './cms/ct-library-list';
import CTLibraryForm from './cms/ct-library-form';
import CTLibraryDetail from './cms/ct-library-detail';
import CTLibraryDetailEdit from './cms/ct-library-detail-edit';
import CTCourse from './cms/ct-course-list';
import CTCourseForm from './cms/ct-course-form';
import CTCourseDetail from './cms/ct-course-detail';
import CTCourseDetailEdit from './cms/ct-course-detail-edit';
import CTTutor from './cms/ct-tutor-list';
import CTTutorForm from './cms/ct-tutor-form';
import CTTutorDetail from './cms/ct-tutor-detail';

import CTPayment from './cms/ct-payment-list';
import CTCompanyTrainings from './cms/ct-companytraining-list';
import CTCompanyTrainingsForm from './cms/ct-companytraining-form';
import CTCompanyTrainingsDetail from './cms/ct-companytraining-detail';

import CTUserTrainings from './cms/ct-usertraining-list';
import CTUserTrainingsForm from './cms/ct-usertraining-form';
import CTUserTrainingsDetail from './cms/ct-usertraining-detail';

import CTFeedback from './cms/ct-feedback-list';
import CTFeedbackForm from './cms/ct-feedback-form';
import CTFeedbackDetail from './cms/ct-feedback-detail';

import CTFaqCourse from './cms/ct-faq-course-list';
import CTFaqCourseForm from './cms/ct-faq-course-form';
import CTFaqCourseDetail from './cms/ct-faq-course-detail';

import CTVoucher from './cms/ct-voucher-list';
import CTVoucherForm from './cms/ct-voucher-form';
import CTVoucherDetail from './cms/ct-voucher-detail';

const App = () => {
  return (
    <>
      { (localStorage.getItem("logged_in") === global.config.randomLoginKey)? 
        <Router>
          <MenuMember/>
          <div className="main">
          <Routes>
            {/* === CONTENT After login ==== */}
            <Route exact path='/' element={<HomeMember/>}></Route>
            <Route path='courses' element={<CourseBrowseMember/>}></Route>
            <Route path='course/:slug' element={<CourseDetailMember/>}></Route>
            <Route path='libraries' element={<LibraryBrowseMember/>}></Route>
            <Route path='library/:slug' element={<LibraryDetailMember/>}></Route> 
            {/* <Route path='enroll/:slug' element={<LibraryEnroll/>}></Route> */}
            
            <Route path='certificate' element={<Certificate/>}></Route>
            <Route path='settings' element={<Setting/>}></Route>

            <Route path='account'>
              <Route index element={<Account />} ></Route>
              <Route path="mycourses">
                <Route index element={<Account />} ></Route>
              </Route>
              <Route path="mylibraries">
                <Route index element={<Account />} ></Route>
              </Route>
              <Route path="mycertificates">
                <Route index element={<Account />} ></Route>
              </Route>
              <Route path="subscription">
                <Route index element={<Account />} ></Route>
              </Route>
              <Route path="myorder">
                <Route index element={<Account />} ></Route>
              </Route>
            </Route>

            {/* <Route path='/courses'><CourseBrowse/></Route>
            <Route path='/course/:slug'><CourseDetail/></Route>
            <Route path='/libraries'><LibraryBrowse/></Route>
            <Route path='/library/:slug'><LibraryDetail/></Route> */}
            
            {/* === TRANSACTION */}
            
            <Route path='subscribe' element={<Subscribe/>}></Route>
            {/*<Route path='invoice'>
               <Route index element={<InvoiceSubscription />} ></Route>
              <Route path=":slug" element={<InvoiceSubscription/>}></Route>
              <Route path="subscription">
                <Route index element={<InvoiceSubscription />} ></Route>
                <Route path=":slug" element={<InvoiceSubscription/>}></Route>
              </Route> 
              <Route path="course">
                <Route index element={<InvoiceCourse />} ></Route>
                <Route path=":slug" element={<InvoiceCourse/>}></Route>
              </Route>
            </Route> 
            */}
            <Route path='orders'>
              <Route index element={<Error404 />} ></Route>
              <Route path=":slug" element={<OrderDetails/>}></Route>
            </Route>
            
            <Route path='checkout' element={<Checkout/>}></Route>
            <Route path='shopping-cart' element={<ShoppingCart/>}></Route>

            {/* <Route path='checkout/:slug'><Checkout/></Route>
            <Route path='invoice/subscription'><InvoiceSubscription/></Route>
            <Route path='invoice/course'><InvoiceCourse/></Route>
            <Route path='invoice'><InvoiceSubscription/></Route>
            <Route path='certificate'><Certificate/></Route>
            <Route path='account'><Account/></Route>
            <Route path='settings'><Setting/></Route>  */}

            {/* === GENERAL FOR TRANSACTION */}
            {/* <Route path='success' element={<Success/>}></Route> */}
            {/* <Route path='/invoice/:slug'><Invoice/></Route>
            <Route path='/success'><Success/></Route> */}
            
            {/* === GENERAL */}
            <Route path='about' element={<About/>}></Route>
            <Route path='help' element={<Help/>}></Route>
            <Route path='privacy-policy' element={<PrivacyPolicy/>}></Route>
            <Route path="na" element={<Error404/>}></Route>
            <Route path="error-403" element={<Error403/>}></Route>
            <Route path="error-204" element={<Error204/>}></Route>
            <Route path="*" element={<Error404/>}></Route>
            

            {/* <Route path="/" element={<Member />}></Route> */}
            {/* <Route path="/c" element={<CMS />}></Route> */}

            { (localStorage.getItem("ulvl") === global.config.userLevel[2] || localStorage.getItem("ulvl") === global.config.userLevel[0]) ? 
            <Route path='c'>
              <Route index element={<CTDashboard />} ></Route>
              <Route path="ctcategory">
                <Route index element={<CTCategory />} ></Route>
                <Route path="course" element={<CTCategory />} ></Route>
                <Route path="library" element={<CTCategory />} ></Route>
                
              </Route>
              <Route path="ctlibrary">
                <Route index element={<CTLibrary />} ></Route>
                {/* <Route path="english">
                  <Route index element={<CTLibrary />} ></Route>
                  <Route path="add" element={<CTLibraryForm />} ></Route>
                  <Route path="edit/:slug" element={<CTLibraryForm />} ></Route>
                  <Route path="view/:slug" element={<CTLibrary />} ></Route>
                </Route>
                <Route path="indonesia" >
                  <Route index element={<CTLibrary />} ></Route>
                  <Route path="add" element={<CTLibraryForm />} ></Route>
                  <Route path="edit/:slug" element={<CTLibraryForm />} ></Route>
                  <Route path="view/:slug" element={<CTLibrary />} ></Route>
                </Route> */}
                <Route path="english" element={<CTLibrary />} ></Route>
                <Route path="indonesia" element={<CTLibrary />} ></Route>
                <Route path="add" element={<CTLibraryForm />} ></Route>
                <Route path="add/:slug" element={<CTLibraryForm />} ></Route>
                <Route path="edit/:slug" element={<CTLibraryForm />} ></Route>
                <Route path="detailedit/:slug" element={<CTLibraryDetailEdit />} ></Route>
                <Route path="view/:slug" element={<CTLibraryDetail />} ></Route>
                
              </Route>
              <Route path="ctcourse">
                <Route index element={<CTCourse />} ></Route>
                <Route path="english" element={<CTCourse />} ></Route>
                <Route path="indonesia" element={<CTCourse />} ></Route>
                <Route path="add" element={<CTCourseForm />} ></Route>
                <Route path="add/:slug" element={<CTCourseForm />} ></Route>
                <Route path="edit/:slug" element={<CTCourseForm />} ></Route>
                <Route path="detailedit/:slug" element={<CTCourseDetailEdit />} ></Route>
                <Route path="view/:slug" element={<CTCourseDetail />} ></Route>
                
              </Route>
              <Route path="cttutor">
                <Route index element={<CTTutor />} ></Route>
                <Route path="add" element={<CTTutorForm />} ></Route>
                <Route path="edit/:slug" element={<CTTutorForm />} ></Route>
                <Route path="view/:slug" element={<CTTutorDetail />} ></Route>
              </Route>
              <Route path="ctpayment">
                <Route index element={<CTPayment />} ></Route>
              </Route>
              <Route path="ctusertraining">
                <Route index element={<CTUserTrainings />} ></Route>
                <Route path="add" element={<CTUserTrainingsForm />} ></Route>
                {/* <Route path="edit/:slug" element={<CTUserTrainingsForm />} ></Route> */}
                <Route path="view/:slug" element={<CTUserTrainingsDetail />} ></Route>
              </Route>
              <Route path="ctcompanytraining">
                <Route index element={<CTCompanyTrainings />} ></Route>
                <Route path="add" element={<CTCompanyTrainingsForm />} ></Route>
                <Route path="edit/:slug" element={<CTCompanyTrainingsForm />} ></Route>
                <Route path="view/:slug" element={<CTCompanyTrainingsDetail />} ></Route>
              </Route>
              <Route path="ctfeedback">
                <Route index element={<CTFeedback />} ></Route>
                <Route path="add" element={<CTFeedbackForm />} ></Route>
                <Route path="edit/:slug" element={<CTFeedbackForm />} ></Route>
                <Route path="view/:slug" element={<CTFeedbackDetail />} ></Route>
              </Route>
              <Route path="ctfaqcourse">
                <Route index element={<CTFaqCourse />} ></Route>
                <Route path="english" element={<CTFaqCourse />} ></Route>
                <Route path="indonesia" element={<CTFaqCourse />} ></Route>
                <Route path="add" element={<CTFaqCourseForm />} ></Route>
                <Route path="add/:slug" element={<CTFaqCourseForm />} ></Route>
                <Route path="edit/:slug" element={<CTFaqCourseForm />} ></Route>
                <Route path="view/:slug" element={<CTFaqCourseDetail />} ></Route>
              </Route>
              <Route path="ctvoucher">
                <Route index element={<CTVoucher />} ></Route>
                <Route path="add" element={<CTVoucherForm />} ></Route>
                <Route path="edit/:slug" element={<CTVoucherForm />} ></Route>
                <Route path="view/:slug" element={<CTVoucherDetail />} ></Route>
              </Route>
            </Route>
            : <></>
            }
          </Routes>
          </div>
          <Footer/>
        </Router>
        : (
        <Router>
          <MenuGuest/>
          <Announcement/>
          <div >
          <Routes>
            <Route exact path='/' element={<HomeGuest/>}></Route>
            {/* === SIGN IN + SIGN UP */}
            <Route path="login">
              <Route index element={<Login />} ></Route>
              <Route path="confirm-otp" element={<Login/>}></Route>
              
              {/* <Route path="reset-success" element={<Login/>}></Route>
              <Route path="reset-expired" element={<Login/>}></Route>
              <Route path="confirm-otp" element={<Login/>}></Route> */}
            </Route>
            <Route path="register">
              <Route index element={<Login />} ></Route>
              <Route path="activation" element={<Login/>}></Route>
            </Route>
            <Route path="forgot">
              <Route index element={<Login />} ></Route>
              <Route path="reset" element={<Login/>}></Route>
            </Route>
            
            {/* <Route path="register" element={<Login/>}></Route>
            <Route path="register-success" element={<Login/>}></Route> */}
            {/* <Route path="reset">
              <Route index element={<Login />} ></Route>
            </Route> */}
            {/* <Route path="/activation" element={<Login/>}></Route> */}
            {/* <Route path="/forgot" element={<Login/>}></Route> */}
            {/* <Route path="/forgot-success" element={<Login/>}></Route> */}
            {/* <Route path="/reset" element={<Login/>}></Route>
            <Route path="/reset-success" element={<Login/>}></Route>
            <Route path="/reset-expired" element={<Login/>}></Route>
            <Route path="/confirm-otp" element={<Login/>}></Route> */}
            
            {/* === CONTENT Before login ==== */}
            <Route path='courses' element={<CourseBrowseGuest/>}></Route>
            <Route path='course/:slug' element={<CourseDetailGuest/>}></Route>
            <Route path='libraries' element={<LibraryBrowseGuest/>}></Route>
            <Route path='library/:slug' element={<LibraryDetailGuest/>}></Route> 
            

            {/* === GENERAL FOR TRANSACTION */}
            {/* <Route path='/invoice/:slug'><Invoice/></Route>
            <Route path='/success'><Success/></Route> */}
            
            {/* === GENERAL */}
            <Route path='about' element={<About/>}></Route>
            <Route path='help' element={<Help/>}></Route>
            <Route path='privacy-policy' element={<PrivacyPolicy/>}></Route>
            <Route path="na" element={<Error404/>}></Route>
            <Route path="error-403" element={<Error403/>}></Route>
            <Route path="error-204" element={<Error204/>}></Route>
            <Route path="*" element={<Error404/>}></Route>

            {/* <Route path="/" element={<Guest />}></Route> */}
            {/* <Route path="/c" element={<CMS />}></Route> */}
          </Routes>
          </div>
          <Footer/>
        </Router>
         )
      }
    </>
  );
};

export default App;
