import React, { Component } from 'react';
import Moment from 'moment';
import { GetText } from './config/helper';

export default class Certificate extends Component {

  render() {
    var day = Moment().format('D');
    var month = Moment().format('MMMM');
    var year = Moment().format('Y');
    var date = day + " " + month + " " + year;
    var type = "library";
    var backgroundImg = process.env.PUBLIC_URL + "/img/certificate.jpg";
    var memberNamez = localStorage.getItem('name');
    var cssNamez = "certificate-name";
    
    if(this.props.background !== undefined && this.props.background !== "") { backgroundImg = this.props.background; } 
    if(this.props.name !== undefined && this.props.name !== "") { memberNamez = this.props.name; }
    if(this.props.type !== undefined && this.props.type !== "") { type = this.props.type; }

    if(localStorage.getItem('mcomp') !== undefined && localStorage.getItem('mcomp') !== "" && localStorage.getItem('mcomp') === "aegis.com.bn") {
      backgroundImg = process.env.PUBLIC_URL + "/img/bg-certificate-aegis2.png"; 
      cssNamez = "certificate-name-o";
    }

    return (
      <div className="certificate" id="certificate">
        <div className="certificate-bg" style={{backgroundImage: "url('" + backgroundImg + "')"}}>
          <div className="certificate-detail">
            <p className="certificate-text">{
            type === 'course' ? 
              GetText() ? 'Sertifikasi Kehadiran' : 'Certificate of Participation' : 
              GetText() ? 'Sertifikat Pelatihan' : 'Certificate of Completion'
              
            }</p>
            <p className="certificate-course">{this.props.title}</p>
            <p className={cssNamez}>{memberNamez}</p>
          </div>
          <div className="certificate-date">
          { GetText() ?
            <p>diselesaikan pada <strong>{date}</strong>, diselengarakan oleh <strong>Novaio Academy</strong></p>
          : 
            <p>completed on the <strong>{date}</strong>, conducted by <strong>Novaio Academy</strong></p>
          }
          </div>
        </div>
      </div>
    );
  }
}