import React, { Component } from 'react';
import { Container, Carousel, Tabs, Tab } from 'react-bootstrap';
// import Moment from 'moment';
// import Jumbotron from 'react-bootstrap/Jumbotron';
import { FaArrowRight, FaArrowDown, FaQuestion, FaBookmark, FaBook, FaAsterisk, FaRegCalendarDays, FaRegClock } from 'react-icons/fa6';
import { GetText, ReplaceText } from '../config/helper';
import NumberFormats from '../config/number-format';
import DateTimeFormats from '../config/date-time-format';
import CourseBlock from '../inc/course/Block';
import LibraryBlock from '../inc/library/Block';
import Loading from '../inc/Loading';
// import ImgCarousel1 from '../img/carousel-1.png';
// import ImgCarousel2 from '../img/carousel-2.png';
// import ImgCarousel3 from '../img/carousel-3.png';
import ImgLogoLogitech from '../img/logo/logitech.png';
import ImgLogoGoogleCloud from '../img/logo/google-cloud.png';
import ImgLogoCiscoMeraki from '../img/logo/cisco-meraki.png';
import ImgLogoMicrosoft from '../img/logo/microsoft.png';
import ImgLogoOracleNetsuite from '../img/logo/oracle-netsuite.png';
import '../config/global';
import '../css/jumbotron.css';
import '../css/carousel.css';

const authKey = global.config.kindcode.training[1]+global.config.authBearerKey;
const authKey2 = global.config.kindcode.training[2]+global.config.authBearerKey;

function getLibraryData() {
  return new Promise((resolve) => {
    var dataText ='{"keyword":"","pageShow":"10","lang":"'+localStorage.getItem('language')+'","form_id":"pubonly"}';
    var dataSend = JSON.parse(dataText);
    
    fetch(global.config.urlLink+"/librarylist", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey
      }, 
      body : JSON.stringify(dataSend)
    }).then(response => {
      if(response.status === 200) {
        return response.json();
      } else {
        console.log('Something happened wrong');
      }
    }).then(resultJson => {
      var librarySend = {};
      var resultData = [];
      var resultDataCategory = [];
      var inum=0;
      if(resultJson.values.length > 0) {
        for (var i in resultJson.values) {
          var content = resultJson.values[i].content;
          content.id = resultJson.values[i].docid;
          resultData.push(content);
          if (resultDataCategory.indexOf(content.categoryName) < 0) {
            resultDataCategory.push(content.categoryName);
          }
          inum++;
          if(inum === resultJson.values.length) {
            librarySend.librarydata = resultData;
            librarySend.librarycategory = resultDataCategory;
            resolve(librarySend);
          }
        }
      } else {
        librarySend.librarydata = resultData;
        librarySend.librarycategory = resultDataCategory;
        resolve(librarySend);
      }
    });
  });
}

function getCourseData() {
  return new Promise((resolve) => {
    var dataText ='{"keyword":"","pageShow":"10","lang":"'+localStorage.getItem('language')+'","form_id":"pubonly"}';
    var dataSend = JSON.parse(dataText);
    
    fetch(global.config.urlLink+"/courselist", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey2
      }, 
      body : JSON.stringify(dataSend)
    })
    .then(response => {
      if(response.status === 200) {
        return response.json();
      } else {
        console.log('Something happened wrong');
      }
    })
    .then(resultJson2 => {
      var courseSend = {};
      var resultDataCourse = [];
      var resultDataCourseFeatured = [];
     
      if(resultJson2.values.length > 0) {
        var courseListFeatured = resultJson2.values.filter((list1) =>  {
          return (list1.content.isFeatured === '1');
        });

        var courseListNoFeatured = resultJson2.values.filter((list2) =>  {
          return (list2.content.isFeatured === undefined || list2.content.isFeatured === '0' || list2.content.isFeatured === '');
        });
        courseSend.coursedata = courseListNoFeatured;
        courseSend.coursefeature = courseListFeatured;
        resolve(courseSend);
      } else {
        courseSend.coursedata = resultDataCourse;
        courseSend.coursefeature = resultDataCourseFeatured;
        resolve(courseSend);
      }
    });
  });
}
async function getAllDatas() {
    const libraryresult = getLibraryData();
    const courseresult = getCourseData();
    
    const [thing1, thing2] = await Promise.all([libraryresult, courseresult]);
    var arrReturnValue = {};
    arrReturnValue.librarydata = thing1.librarydata;
    arrReturnValue.librarycategory = thing1.librarycategory;

    arrReturnValue.coursedata = thing2.coursedata;
    arrReturnValue.coursefeatures = thing2.coursefeature;
    
    return arrReturnValue;
}
export default class HomeGuest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courseList: [],
      courseFeatured: [],
      libraryList: [],
      libraryCategories: []
    }
  }

  componentDidMount() {
    getAllDatas().then((response) => {
      this.setState({
        courseList: response.coursedata,
        courseFeatured: response.coursefeatures,
        libraryList: response.librarydata,
        libraryCategories: response.librarycategory,
      }) 
    });
  }
  
  render() {
    return (
      <div className="home">
        <section>
          <Container>
            <div className="banner-home text-start text-white bg-dark jumbotron">
              <div className="row">
                <div className="col-md-8">
                  {
                    GetText() ? (
                      <div className="mt-5 mb-5">
                        <h1>Daftar dan dapatkan kelas workshop baru setiap bulannya</h1>
                        <p>Ekosistem Cloud selalu berubah, perbarui pengetahuan teknologi informasi dan digitalmu hanya di NOVAIO ACADEMY</p>
                        <a href="/login" className="btn btn-lg btn-primary mt-3">Daftar Sekarang!</a>
                      </div>
                    ) : (
                      <div className="mt-5 mb-5">
                        <h1>Join our new online workshops every month</h1>
                        <p>The cloud ecosystem is constantly changing, keep up with the latest developments in technology and information only at NOVAIO ACADEMY</p>
                        <a href="/login" className="btn btn-lg btn-primary mt-3">Join Now!</a>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </Container>
          <div className="separator"><div className="icon"><FaArrowDown/></div></div>
        </section>
        {
          this.state.courseList.length > 0 ? (
            <section>
              <Container>
                <div className="bg-light p-5 br-8">
                  {
                    GetText() ? (
                      <>
                      <div>
                        <h4 className="text-start mb-1">Workshop Terbaru</h4>
                        <p className="text-start mb-4">Belajar skill baru. Cari dan belajar hari ini juga!</p>
                      </div>
                      </>
                    ) : (
                      <>
                      <div>
                        <h4 className="text-start mb-1">Upcoming Workshops</h4>
                        <p className="text-start mb-4">Learn the skills you need. Find a class and register today!</p>
                      </div>
                      </>                      
                    )
                  }
                  <div className="row">
                    {
                      this.state.courseList
                      .sort((a,b) => b.content.courseDate._seconds - a.content.courseDate._seconds)
                      .slice(0,4)
                      .map((courseItem, i) => {
                        return (
                          <div className="col-md-3" key={i} >
                            <CourseBlock value={courseItem}/>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
                <div className="mt-4 text-end">
                  <a href="/courses" className="link-more">{GetText() ? "Workshop Lainnya" : "Browse Workshops"} <FaArrowRight/></a>
                </div>
              </Container>
              <div className="separator"><div className="icon"><FaQuestion/></div></div>
            </section>
          ) : ''
        }
        <section className="slider">
          <Container>
            <div className="row justify-content-center">
              <div className="col-md-8">
                <h4 className="text-center">
                  <span className="label-rounded">{GetText() ? "Kenapa Harus Mengikuti Workshop?" : "Why You Should Join Our Workshops?"}</span>
                </h4>
                <Carousel slide={false} fade={true}>
                  <Carousel.Item >
                  <div className="align-items-center mx-0 w-full" style={{height:"380px"}}>
                    {
                      GetText() ? (
                        <Carousel.Caption>
                          <img src={process.env.PUBLIC_URL + '/img/illustration-1.jpg'} alt="Belajar bersama ahlinya"/>
                          <h3 className="text-dark">Belajar bersama ahlinya</h3>
                          <p className="text-dark">Belajar dari yang terbaik dalam bidangnya dengan pelatihan yang dipimpin pakar kami dan dirancang untuk membantu Anda mengembangkan keterampilan dan pengetahuan yang Anda butuhkan untuk terus maju dalam karier dan bisnis. Kursus kami mencakup berbagai topik dan tersedia 24/7 di perangkat apa pun.</p>
                        </Carousel.Caption>
                      ) : (
                        <Carousel.Caption>
                          <img src={process.env.PUBLIC_URL + '/img/illustration-1.jpg'} alt="Expert-Led Courses"/>
                          <h3 className="text-dark">Expert-Led Courses</h3>
                          <p className="text-dark">Learn from the best in the industry with our expert-led courses designed to help you develop the skills and knowledge you need to succeed in your career or business. Our courses cover a wide range of topics and are available 24/7 on any device.</p>
                        </Carousel.Caption>
                      )
                    }
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                  <div className="align-items-center mx-0 w-full" style={{height:"380px"}}>
                    {
                      GetText() ? (
                        <Carousel.Caption>
                          <img src={process.env.PUBLIC_URL + '/img/illustration-2.jpg'} alt="Workshop yang interaktif"/>
                          <h3 className="text-dark">Workshop yang interaktif</h3>
                          <p className="text-dark">Berpartisipasi dalam workshop interaktif kami, di mana Anda dapat berkolaborasi dengan profesional lain dan belajar dari beberapa pembicara ahli tentang berbagai topik mulai dari kepemimpinan dan kewirausahaan hingga teknologi dan inovasi, tingkatkan karier Anda ke level berikutnya.</p>
                        </Carousel.Caption>
                      ) : (
                        <Carousel.Caption>
                          <img src={process.env.PUBLIC_URL + '/img/illustration-2.jpg'} alt="Interactive Workshops"/>
                          <h3 className="text-dark">Interactive Workshops</h3>
                          <p className="text-dark">Participate in our interactive workshops, where you can collaborate with other professionals and learn from multiple expert speakers on a variety of topics from leadership and entrepreneurship to technology and innovation, take your career to the next level.</p>
                        </Carousel.Caption>
                      )
                    }
                  </div>
                  </Carousel.Item>
                  <Carousel.Item>
                  <div className="align-items-center mx-0 w-full" style={{height:"380px"}}>
                    {
                      GetText() ? (
                        <Carousel.Caption>
                          <img src={process.env.PUBLIC_URL + '/img/illustration-3.jpg'} alt="Integrasi dengan Cloud Platform"/>
                          <h3 className="text-dark">Integrasi dengan Cloud Platform</h3>
                          <p className="text-dark">Manfaatkan integrasi Cloud Platform kami yang memberi Anda alat dan sumber daya untuk mendigitalkan aktivitas bisnis Anda. Workshop dan pelatihan kami akan mengajari Anda bagaimana cara menggunakan Cloud Platform secara efektif untuk mengoptimalkan modal bisnis dan meningkatkan produktivitas.</p>
                        </Carousel.Caption>
                      ) : (
                        <Carousel.Caption>
                          <img src={process.env.PUBLIC_URL + '/img/illustration-3.jpg'} alt="Cloud Platform Integration"/>
                          <h3 className="text-dark">Cloud Platform Integration</h3>
                          <p className="text-dark">Take advantage of our cloud platform integration, which provides you with the tools and resources to digitalize your activities and businesses. Our courses and workshops will teach you how to use cloud platforms effectively to optimize your operations and increase productivity.</p>
                        </Carousel.Caption>
                      )
                    }
                  </div>
                  </Carousel.Item>
                  <Carousel.Item>
                  <div className="align-items-center mx-0 w-full" style={{height:"380px"}}>
                    {
                      GetText() ? (
                        <Carousel.Caption>
                          <img src={process.env.PUBLIC_URL + '/img/illustration-4.jpg'} alt="Program Karir Mentoring"/>
                          <h3 className="text-dark">Program Karir Mentoring</h3>
                          <p className="text-dark">Tingkatkan keterampilan profesional dan karier Anda dengan pelatihan pengembangan karier dari kami. Program pelatihan ini dirancang untuk membantu Anda memperoleh pengetahuan dan keterampilan yang Anda butuhkan untuk unggul dalam peran Anda saat ini atau mengejar peluang baru.</p>
                        </Carousel.Caption>
                      ) : (
                        <Carousel.Caption>
                          <img src={process.env.PUBLIC_URL + '/img/illustration-4.jpg'} alt="Career Development Training"/>
                          <h3 className="text-dark">Career Development Training</h3>
                          <p className="text-dark">Enhance your professional skills and advance your career with our career development training courses. Our training programs are designed to help you acquire the knowledge and skills you need to excel in your current role or pursue new opportunities in your field.</p>
                        </Carousel.Caption>
                      )
                    }
                    </div>
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>
          </Container>
          <div className="separator"><div className="icon"><FaBookmark/></div></div>
        </section>
        {
          this.state.courseFeatured.length > 0 ? (
            <section className="featured-home">
              <Container>

                <Carousel id="course-featured" slide={true} fade={true} indicators={false}>
                  {
                    this.state.courseFeatured
                    .sort((a,b) => b.content.courseDate._seconds - a.content.courseDate._seconds)
                    .slice(0,4)
                    .map((courseItemFeatured, i) => {
                      var courseListFeatured = courseItemFeatured.content;
                      return (
                        <Carousel.Item >
                          <div className="row align-items-stretch mx-0">
                            <div className="col-md-5 bg-light p-5 br-8">
                              <div className="row">
                                <div className="col">
                                  <h4>{GetText() ? "Workshop Unggulan" : "Featured Workshop"}</h4>              
                                </div>
                                <div className="col-auto text-end">
                                  <p className="text-red mt-1"><strong>{courseListFeatured.categoryName}</strong></p>
                                </div>
                              </div>
                              <div className="text-center">
                                <img className="mxw h-300 br-8 mt-2" style={{maxWidth:"360px", maxHeight:"400px"}} src={courseListFeatured.coursePicture} alt={courseListFeatured.courseTitle}/>
                                <h5 className="my-4" style={{height:"50px"}}>{courseListFeatured.courseTitle}</h5>
                                <p>
                                  <FaRegCalendarDays className="me-1"/>
                                  {courseListFeatured.courseDate ? DateTimeFormats(courseListFeatured.courseDate._seconds,"date-long") : ''}
                                  <FaRegClock className="ms-3 me-1"/>
                                  {courseListFeatured.courseDate ? DateTimeFormats(courseListFeatured.courseStartTime._seconds,"time-24") : ''}
                                </p>
                                {/* <p>{ReplaceText(courseListFeatured.courseShortDesc)}</p> */}
                                <p><strong>{courseListFeatured.courseCurrency === global.config.currency.name[0] ? global.config.currency.symbol[0] : global.config.currency.symbol[1]} {NumberFormats(courseListFeatured.coursePrice)}</strong></p>
                                <p><a href={"/login/?redirect=checkout/" + courseItemFeatured.docid} className="btn btn-lg btn-outline-primary">{GetText() ? "Pesan Tiket" : "Book Ticket"}</a></p>
                                <p><a href={"/course/" + courseItemFeatured.docid} className="link-more">{GetText() ? "Selengkapnya" : "View Detail"} <FaArrowRight/></a></p>
                              </div>
                            </div>
                            <div className="col-md-1"></div>
                            <div className="col-md-6 agenda p-5 br-8" style={{height:"700px"}}>
                              <h4 className="text-start mb-4">{GetText() ? "Pengantar" : "Introduction"}</h4>
                              <p className="text-start mb-5">{courseListFeatured.courseShortDesc}</p>
                              {
                                (courseItemFeatured.subcontentlist.length > 0) ? (
                                  <div className="agenda-list">
                                    <h4 className="text-start mb-4">Agenda</h4>
                                    {
                                      courseItemFeatured.subcontentlist
                                        .sort((a, b) => a.subcontent.agendaTime._seconds - b.subcontent.agendaTime._seconds)
                                        .map((agenda, i) => {
                                        return (
                                          <div className="row agenda-item" key={i}>
                                            <div className="col-md-3">
                                              <label className="label-rounded-2">{DateTimeFormats(agenda.subcontent.agendaTime._seconds,"time-24")}</label>
                                            </div>
                                            <div className="col-md-9 text-start">
                                              <h5>{agenda.subcontent.agendaTitle}</h5>
                                              <p className="clamp-3">{ReplaceText(agenda.subcontent.agendaDesc)}</p>
                                            </div>
                                          </div>
                                        )
                                      })
                                    }
                                  </div>  
                                ) : ''
                              }
                              <div className="agenda-more"></div>
                            </div>
                          </div>
                        </Carousel.Item>
                      )
                    })
                  }
                    
                  </Carousel>
                
              </Container>
              <div className="separator"><div className="icon"><FaBook/></div></div>
            </section>
          ) : ''
        }
        {
          this.state.libraryList.length > 0 ? (
            <section>
              <Container>
                <div className="bg-light p-5 br-8">
                  {
                    GetText() ? (
                      <>
                        <h4 className="text-start mb-1">Library</h4>
                        <p className="text-start mb-4">Pilihan beragam konten pembelajaran untuk mendukung pengembangan profesional maupun</p>
                      </>
                    ) : (
                      <>
                        <h4 className="text-start mb-1">Library</h4>
                        <p className="text-start mb-4">Choose from a variety of library contents to support your personal and professional development</p>
                      </>                      
                    )
                  }
                  <Tabs className="nav-scroll" defaultActiveKey="0" variant="pills" id="tab">
                    {
                      this.state.libraryCategories
                      .sort((a, b) => { return (a < b) ? -1 : ((a > b) ? 1 : 0) })
                      .map((libraryCategory, i) => {
                        return (
                          <Tab className="pt-4" eventKey={i} title={libraryCategory} key={i}>
                            <div className="row">
                              {
                                this.state.libraryList
                                .sort((a, b) => b.createDate._seconds - a.createDate._seconds)
                                .filter(libraryItem => libraryItem.categoryName === libraryCategory)
                                .slice(0, 4)
                                .map((libraryItem, j) => {
                                  return (
                                    <div className="col-md-3" key={j}>
                                      <LibraryBlock value={libraryItem} key={"library" + j}/>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </Tab>
                        )
                      })
                    }
                  </Tabs>
                </div>
                <div className="mt-4 text-end">
                  <a href="/libraries" className="link-more">{GetText() ? "Library Lainnya" : "Browse Libraries"} <FaArrowRight/></a>
                </div>
              </Container>
              <div className="separator"><div className="icon"><FaArrowDown/></div></div>
            </section>
          ) : <Loading value={{id:'library-area', divstyle:{}, componentstyle: {}}}/>
        }
        <section>
          <Container>
            <div className="text-center text-white bg-dark banner-second jumbotron">
              {
                GetText() ? (
                  <>
                    <h2>Dapatkan tiket workshop pertamamu hari ini!</h2>
                    <p>Setiap kelas termasuk 1 sertifikat, 100 peserta, 5 sesi, dan lebih dari 1 pembicara.</p>
                    <a href="/login" className="btn btn-lg btn-primary mt-3">Daftar Sekarang!</a>
                  </>
                ) : (
                  <>
                    <h2>Get your first workshop ticket today!</h2>
                    <p>Each class consists of 1 certificate, 100 class participants, 5 sessions, and more than 1 keynote.</p>
                    <a href="/login" className="btn btn-lg btn-primary mt-3">Register Now!</a>
                  </>
                )
              }
            </div>
          </Container>
          <div className="separator"><div className="icon"><FaAsterisk/></div></div>
        </section>
        <section>
          <Container>
            <div className="p-4 text-center">
            {
                GetText() ? (
                  <>
                    <h4 className="text-center"><span className="label-rounded">Disponsori oleh</span></h4>
                    <p className="p-4">Kami mendapat dukungan dari banyak sponsor yang berpartisipasi dalam menyelenggarakan acara ini</p>
                  </>
                ) : (
                  <>
                    <h4 className="text-center"><span className="label-rounded">Sponsored by</span></h4>
                    <p className="p-4">We have the support of many sponsors who participated in organizing this event</p>
                  </>
                )
              }
              <div className="row justify-content-center p-4">
                <div className="col-6 col-md-2 mb-sm-4 mb-4">
                  <img className="w-100" src={ImgLogoLogitech} alt="Logitech"/>
                </div>
                <div className="col-6 col-md-2 mb-sm-4 mb-4">
                  <img className="w-100" src={ImgLogoGoogleCloud} alt="Google Cloud"/>
                </div>
                <div className="col-6 col-md-2 mb-sm-4 mb-4">
                  <img className="w-100" src={ImgLogoCiscoMeraki} alt="Cisco Meraki"/>
                </div>
                <div className="col-6 col-md-2 mb-sm-4 mb-4">
                  <img className="w-100" src={ImgLogoMicrosoft} alt="Microsoft"/>
                </div>
                <div className="col-6 col-md-2 mb-sm-4 mb-4">
                  <img className="w-100" src={ImgLogoOracleNetsuite} alt="Oracle NetSuite"/>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
    );
  }
}