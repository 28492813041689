import React, { Component } from 'react';
import { Container, Tabs, Tab, Carousel } from 'react-bootstrap';
// import Moment from 'moment';
// import Jumbotron from 'react-bootstrap/Jumbotron';
import { FaArrowRight, FaArrowDown, FaBookmark, FaBook, FaRegCalendarDays, FaRegClock } from 'react-icons/fa6';
import { GetText, ReplaceText } from '../config/helper';
import NumberFormats from '../config/number-format';
import DateTimeFormats from '../config/date-time-format';
import CourseBlock from '../inc/course/Block';
import CoursePurchased from '../inc/course/Purchased';
import LibraryBlock from '../inc/library/Block';
import LibraryPurchased from '../inc/library/Purchased';
import Loading from '../inc/Loading';
import '../config/global';
import '../css/jumbotron.css';


const authKey = global.config.kindcode.training[2]+global.config.authBearerKey;
const authKey2 = global.config.kindcode.training[1]+global.config.authBearerKey;
const authKey3 = global.config.kindcode.training[0]+global.config.authBearerKey;

function getLibraryData() {
  return new Promise((resolve) => {
    var dataText ='{"keyword":"","pageShow":"10","lang":"'+localStorage.getItem('language')+'","form_id":"pubonly"}';
    var dataSend = JSON.parse(dataText);
    
    fetch(global.config.urlLink+"/librarylist", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey2
      }, 
      body : JSON.stringify(dataSend)
    }).then(response => {
      if(response.status === 200) {
        return response.json();
      } else {
        console.log('Something happened wrong');
      }
    }).then(resultJson => {
      var librarySend = {};
      var resultData = [];
      var resultDataCategory = [];
      var inum=0;
      if(resultJson.values.length > 0) {
        for (var i in resultJson.values) {
          var content = resultJson.values[i].content;
          content.id = resultJson.values[i].docid;
          resultData.push(content);
          if (resultDataCategory.indexOf(content.categoryName) < 0) {
            resultDataCategory.push(content.categoryName);
          }
          inum++;
          if(inum === resultJson.values.length) {
            librarySend.librarydata = resultData;
            librarySend.librarycategory = resultDataCategory;
            resolve(librarySend);
          }
        }
      } else {
        librarySend.librarydata = resultData;
        librarySend.librarycategory = resultDataCategory;
        resolve(librarySend);
      }
    });
  });
}

function getCourseData() {
  return new Promise((resolve) => {
    var dataText ='{"keyword":"","pageShow":"10","lang":"'+localStorage.getItem('language')+'","form_id":"pubonly"}';
    var dataSend = JSON.parse(dataText);
    
    fetch(global.config.urlLink+"/courselist", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey
      }, 
      body : JSON.stringify(dataSend)
    })
    .then(response => {
      if(response.status === 200) {
        return response.json();
      } else {
        console.log('Something happened wrong');
      }
    })
    .then(resultJson2 => {
      var courseSend = {};
      var resultDataCourse = [];
      var resultDataCourseFeatured = [];
      
      if(resultJson2.values.length > 0) {
        var courseListFeatured = resultJson2.values.filter((list1) =>  {
          return (list1.content.isFeatured === '1');
        });

        var courseListNoFeatured = resultJson2.values.filter((list2) =>  {
          return (list2.content.isFeatured === undefined || list2.content.isFeatured === '0' || list2.content.isFeatured === '');
        });
        courseSend.coursedata = courseListNoFeatured;
        courseSend.coursefeature = courseListFeatured;
        resolve(courseSend);
      } else {
        courseSend.coursedata = resultDataCourse;
        courseSend.coursefeature = resultDataCourseFeatured;
        resolve(courseSend);
      }
    });
  });
}
function getUserOrder() {
  return new Promise((resolve) => {
    // fetch(global.config.urlLink+"/traininguserview/" + localStorage.getItem('doc_id'), {
    //   method: 'GET',
    //   headers: {
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json',
    //     'Access-Control-Allow-Origin': '*',
    //     'Authorization': authKey3
    //   }
    // })
    var dataSend = { docid: localStorage.getItem('doc_id') }
    fetch(global.config.urlLink+"/traininguserview", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey3
      }, 
      body : JSON.stringify(dataSend)
    }).then(response => {
      if(response.status === 200) {
        return response.json();
      } else {
        console.log('Something happened wrong.');
      }
    }).then(resJson => {
      var userOrderSend = {};
      userOrderSend.purchase = resJson.values[0].scontent2;
      userOrderSend.enrolls = resJson.values[0].scontent3;
      resolve(userOrderSend);
    });
  });
}
async function getAllDatas() {
    const libraryresult = getLibraryData();
    const courseresult = getCourseData();
    const userorderresult = getUserOrder();
  
    const [thing1, thing2, thing3] = await Promise.all([libraryresult, courseresult, userorderresult]);
    var arrReturnValue = {};
    arrReturnValue.librarydata = thing1.librarydata;
    arrReturnValue.librarycategory = thing1.librarycategory;

    arrReturnValue.coursedata = thing2.coursedata;
    arrReturnValue.coursefeatures = thing2.coursefeature;
    
    arrReturnValue.purchase = thing3.purchase;
    arrReturnValue.enrolls = thing3.enrolls;
    
    return arrReturnValue;
}
export default class HomeMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      memberId: localStorage.getItem('doc_id'),
      memberStatus: localStorage.getItem('status'),
      courseList: [],
      courseFeatured: [],
      coursePurchased: [],
      libraryList: [],
      libraryCategories: [],
      libraryEnrolled: []
    }
  }

  
  
  componentDidMount() {    
    getAllDatas().then((response) => {
      this.setState({
        courseList: response.coursedata,
        courseFeatured: response.coursefeatures,
        coursePurchased: response.purchase,
        libraryList: response.librarydata,
        libraryCategories: response.librarycategory,
        libraryEnrolled: response.enrolls.filter(libEnrollItem => (libEnrollItem.subcontent.libraryPicture !== undefined && libEnrollItem.subcontent.libraryPicture !== ""))
      }) 
    });
    
  }

  render() {
    return (
      <div className="home">
        <section>
          <Container>
            <div className="banner-member text-start p-4 jumbotron">
              <div className="row align-items-center">
                <div className="col-md-8">
                  {
                    this.state.memberStatus === 'Member' ? (
                      GetText() ? (
                        <>
                          <h1>Ayo mulai belajar!</h1>
                          {/* <p>Cari kelas workshop unggulan sesuai kebutuhan kamu</p> */}
                          <p>Cari topik unggulan sesuai kebutuhan kamu</p>
                          <a href="/libraries" className="btn btn-lg btn-primary mt-3">Kunjungi Library</a>
                        </>
                      ) : (
                        <>
                          <h1>Let's start learning!</h1>
                          {/* <p>Search for featured workshop recommended for you</p> */}
                          <p>Search for the topic learning which recommended for you</p>
                          <a href="/libraries" className="btn btn-lg btn-primary mt-3">Go to Library</a>
                        </>
                      )
                    ) : (
                      GetText() ? (
                        <>
                          <h1>Ambil keuntungan kamu hari ini!</h1>
                          <p>Jadilah peserta pelatihan dan dapatkan sertifikatnya</p>
                          <a href="/subscribe" className="btn btn-lg btn-primary mt-3">Lihat Daftar Harga</a>
                        </>
                      ) : (
                        <>
                          <h1>Claim your benefit today!</h1>
                          <p>Become a training member and get your certificate</p>
                          <a href="/subscribe" className="btn btn-lg btn-primary mt-3">See Our Plans</a>
                        </>
                      )
                    )
                  }
                </div>
                <div className="col-md-4">
                  <img className="w-100" src={process.env.PUBLIC_URL + '/img/member-banner.png'} alt=">Claim your benefit today!"/>
                </div>
              </div>
            </div>
          </Container>
          <div className="separator"><div className="icon"><FaArrowDown/></div></div>
        </section>
        {
          this.state.courseList.length > 0 ? (
            <section>
              <Container>
                <div className="bg-light p-4 br-8">
                {
                    GetText() ? (
                      <>
                        <h4 className="text-start mb-1">Workshop Terbaru</h4>
                        <p className="text-start mb-4">Belajar skill baru. Temukan dan belajar hari ini juga!</p>
                      </>
                    ) : (
                      <>
                        <h4 className="text-start mb-1">Upcoming Workshops</h4>
                        <p className="text-start mb-4">Learn the skills you need. Find a workshop class today!</p>
                      </>
                    )
                  }
                  <div className="row">
                    {
                      this.state.courseList
                      .sort((a,b) => b.content.courseDate._seconds - a.content.courseDate._seconds)
                      .slice(0, 4)
                      .map((courseItem, i) => {
                        return (
                          <div className="col-md-3" key={i}>
                            <CourseBlock value={courseItem} key={"course" + i}/>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
                <div className="py-3 text-center text-md-end">
                  <a href="/courses" className="link-more"><strong>{GetText() ? "Workshop Lainnya" : "Browse Workshops"} <FaArrowRight/></strong></a>
                </div>
              </Container>
              <div className="separator"><div className="icon"><FaBook/></div></div>
            </section>
          ) : ''
        }
        {
          this.state.courseFeatured.length > 0 ? (
            <section className="featured-home">
              <Container>

                <Carousel id="course-featured" slide={true} fade={true} indicators={false}>
                  {
                    this.state.courseFeatured
                    .sort((a,b) => b.content.courseDate._seconds - a.content.courseDate._seconds)
                    .slice(0,4)
                    .map((courseItemFeatured, i) => {
                      var courseListFeatured = courseItemFeatured.content;
                      return (
                        <Carousel.Item >
                          <div className="row align-items-stretch mx-0">
                            <div className="col-md-5 bg-light p-5 br-8">
                              <div className="row">
                                <div className="col">
                                  <h4>{GetText() ? "Workshop Unggulan" : "Featured Workshop"}</h4>              
                                </div>
                                <div className="col-auto text-end">
                                  <p className="text-red mt-1"><strong>{courseListFeatured.categoryName}</strong></p>
                                </div>
                              </div>
                              <div className="text-center">
                                <img className="mxw h-300 br-8 mt-2" style={{maxWidth:"360px", maxHeight:"400px"}} src={courseListFeatured.coursePicture} alt={courseListFeatured.courseTitle}/>
                                <h5 className="my-4" style={{height:"50px"}}>{courseListFeatured.courseTitle}</h5>
                                <p>
                                  <FaRegCalendarDays className="me-1"/>
                                  {courseListFeatured.courseDate ? DateTimeFormats(courseListFeatured.courseDate._seconds,"date-long") : ''}
                                  <FaRegClock className="ms-3 me-1"/>
                                  {courseListFeatured.courseDate ? DateTimeFormats(courseListFeatured.courseStartTime._seconds,"time-24") : ''}
                                </p>
                                {/* <p>{ReplaceText(courseListFeatured.courseShortDesc)}</p> */}
                                <p><strong>{courseListFeatured.courseCurrency === global.config.currency.name[0] ? global.config.currency.symbol[0] : global.config.currency.symbol[1]} {NumberFormats(courseListFeatured.coursePrice)}</strong></p>
                                <p><a href={"/course/" + courseItemFeatured.docid} className="link-more">{GetText() ? "Selengkapnya" : "View Detail"} <FaArrowRight/></a></p>
                              </div>
                            </div>
                            <div className="col-md-1"></div>
                            <div className="col-md-6 agenda p-5 br-8" style={{height:"700px"}}>
                              <h4 className="text-start mb-4">{GetText() ? "Pengantar" : "Introduction"}</h4>
                              <p className="text-start mb-5">{courseListFeatured.courseShortDesc}</p>
                              {
                                (courseItemFeatured.subcontentlist.length > 0) ? (
                                  <div className="agenda-list">
                                    <h4 className="text-start mb-4">Agenda</h4>
                                    {
                                      courseItemFeatured.subcontentlist
                                        .sort((a, b) => a.subcontent.agendaTime._seconds - b.subcontent.agendaTime._seconds)
                                        .map((agenda, i) => {
                                        return (
                                          <div className="row agenda-item" key={i}>
                                            <div className="col-md-3">
                                              <label className="label-rounded-2">{DateTimeFormats(agenda.subcontent.agendaTime._seconds,"time-24")}</label>
                                            </div>
                                            <div className="col-md-9 text-start">
                                              <h5>{agenda.subcontent.agendaTitle}</h5>
                                              <p className="clamp-3">{ReplaceText(agenda.subcontent.agendaDesc)}</p>
                                            </div>
                                          </div>
                                        )
                                      })
                                    }
                                  </div>  
                                ) : ''
                              }
                              <div className="agenda-more"></div>
                            </div>
                          </div>
                        </Carousel.Item>
                      )
                    })
                  }
                    
                  </Carousel>
              
                
              </Container>
              <div className="separator"><div className="icon"><FaBook/></div></div>
            </section>
          ) : ''
        }
        {
          this.state.libraryList.length > 0 ? (
            <section>
              <Container>
                <div className="bg-light p-4 br-8">
                  {
                    GetText() ? (
                      <>
                        <h4 className="text-start mb-1">Library</h4>
                        <p className="text-start mb-4">Pilihan beragam konten pembelajaran untuk mendukung pengembangan profesional maupun</p>
                      </>
                    ) : (
                      <>
                        <h4 className="text-start mb-1">Library</h4>
                        <p className="text-start mb-4">Choose from a variety of library contents to support your personal and professional development</p>
                      </>                      
                    )
                  }
                  <Tabs className="nav-scroll" defaultActiveKey="0" variant="pills" id="tab">
                    {
                      this.state.libraryCategories
                      .sort((a, b) => { return (a < b) ? -1 : ((a > b) ? 1 : 0) })
                      .map((libraryCategory, i) => {
                        return (
                          <Tab className="pt-4" eventKey={i} title={libraryCategory} key={i}>
                            <div className="row">
                              {
                                this.state.libraryList
                                .sort((a, b) => b.createDate._seconds - a.createDate._seconds)
                                .filter(libraryItem => libraryItem.categoryName === libraryCategory)
                                .slice(0, 4)
                                .map((libraryItem, j) => {
                                  return (
                                    <div className="col-md-3" key={j}>
                                      <LibraryBlock value={libraryItem}/>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </Tab>
                        )
                      })
                    }
                  </Tabs>
                </div>
                <div className="mt-4 text-center text-md-end">
                  <a href="/libraries" className="link-more">{GetText() ? "Library Lainnya" : "Browse Libraries"} <FaArrowRight/></a>
                </div>
              </Container>
              <div className="separator"><div className="icon"><FaBookmark/></div></div>
            </section>
          ) : <Loading value={{id:'library-area', divstyle:{}, componentstyle: {}}}/>
        }
        {
          (this.state.coursePurchased.length > 1) && (this.state.libraryEnrolled.length > 1) ? (
            <section>
              <Container>
                <div className="row">
                { (this.state.coursePurchased.length > 1) ? 
                  <div className="col-md-6">
                    <div className="bg-light p-4 br-8">
                      <h4 className="text-start mb-4">{GetText() ? "Workshop yang dibeli" : "Purchased Workshops"}</h4>
                      {
                        this.state.coursePurchased
                        .sort((a,b) => b.subcontent.courseStartTime._seconds - a.subcontent.courseStartTime._seconds)
                        .slice(0, 2)
                        .map((classPurchased, i) => {
                          var coursePurchaseSend = classPurchased.subcontent;
                          coursePurchaseSend.id = classPurchased.subdocid;
                          return (
                            <CoursePurchased value={coursePurchaseSend} key={"purchased-"+i}/>
                          )
                        })
                      }
                    </div>
                    <div className="mt-3 mb-4 mb-md-0 text-center text-md-end">
                      <a href="/account/mycourses" className="link-more"><strong>{GetText() ? "Selengkapnya" : "View More"} <FaArrowRight/></strong></a>
                    </div>
                  </div>
                  : ''
                }
                { (this.state.libraryEnrolled.length > 1) ? 
                  <div className="col-md-6">
                    <div className="bg-light p-4 br-8">
                      <h4 className="text-start mb-4">{GetText() ? "Library yang Diikuti" : "Enrolled Libraries"}</h4>
                      {
                        this.state.libraryEnrolled
                        .sort((c,d) => d.subcontent.lastVisit._seconds - c.subcontent.lastVisit._seconds)
                        .slice(0, 2)
                        .map((libraryEnrolled, j) => {
                          var libraryEnrolledSend = libraryEnrolled.subcontent;
                          libraryEnrolledSend.id = libraryEnrolled.subdocid;
                          return (
                            <LibraryPurchased value={libraryEnrolledSend} key={"enrolled-"+j}/>
                          )
                        })
                      }
                    </div>
                    <div className="mt-3 text-center text-md-end">
                      <a href="/account/mylibraries" className="link-more"><strong>{GetText() ? "Selengkapnya" : "View More"} <FaArrowRight/></strong></a>
                    </div>
                  </div>
                  : ''
                }
                </div>
              </Container>
            </section>
          ) : (this.state.coursePurchased.length > 1) && (this.state.libraryEnrolled.length === 0) ? (
            <section>
              <Container>
                <div className="bg-light p-4 br-8">
              
                  <h4 className="text-start mb-4">{GetText() ? "Workshop yang dibeli" : "Purchased Workshops"}</h4>
                  <div className="row">
                    {
                        this.state.coursePurchased
                        .sort((a,b) => b.subcontent.courseStartTime._seconds - a.subcontent.courseStartTime._seconds)
                        .slice(0, 2)
                        .map((classPurchased, i) => {
                          var coursePurchaseSend = classPurchased.subcontent;
                          coursePurchaseSend.id = classPurchased.subdocid;
                          return (
                            <div className="col-md-6" key={i}>
                              <CoursePurchased value={coursePurchaseSend} key={"purchased-"+i}/>
                            </div>
                          )
                        })
                    }
                  </div>
                </div>
                <div className="py-3 text-center text-md-end">
                  <a href="/account/mycourses" className="link-more"><strong>{GetText() ? "Selengkapnya" : "View More"} <FaArrowRight/></strong></a>
                </div>
              </Container>
              <div className="separator"><div className="icon"><FaBook/></div></div>
            </section>
          ) : (this.state.coursePurchased.length === 0) && (this.state.libraryEnrolled.length > 1) ? (
            <section>
              <Container>
                <div className="bg-light p-4 br-8">
                  <h4 className="text-start mb-4">{GetText() ? "Library yang Diikuti" : "Enrolled Libraries"}</h4>
                  <div className="row">
                      {
                        this.state.libraryEnrolled
                        .sort((c,d) => d.subcontent.lastVisit._seconds - c.subcontent.lastVisit._seconds)
                        .slice(0, 2)
                        .map((libraryEnrolled, j) => {
                          var libraryEnrolledSend = libraryEnrolled.subcontent;
                          libraryEnrolledSend.id = libraryEnrolled.subdocid;
                          return (
                            <div className="col-md-6" key={j}>
                              <LibraryPurchased value={libraryEnrolledSend} key={"enrolled-"+j}/>
                            </div>
                          )
                        })
                      }
                  </div>
                </div>
                <div className="mt-4 text-center text-md-end">
                  <a href="/account/mylibraries" className="link-more">{GetText() ? "Selengkapnya" : "View More"} <FaArrowRight/></a>
                </div>
              </Container>
            </section>
          ) : ''
        }
      </div>
    );
  }
}