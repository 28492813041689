import React, { Component } from 'react';
import parse from 'html-react-parser';
import { Container } from 'react-bootstrap';
import { GetText, ReplaceText } from '../config/helper';
import Loading from '../inc/Loading';
import '../config/global';

const authKey = global.config.kindcode.training[1]+global.config.authBearerKey;

export default class LibraryDetailGuest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      title: '',
      category: '',
      excerpt: '',
      image: '',
      link: '',
      lesson: [],
      isLoad: false
    }
  }

  componentDidMount() {
    var url = window.location.href;
    var id = url.substring(url.lastIndexOf('/') + 1);

    fetch(global.config.urlLink+"/libraryview/" + id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey
      }
    })
    .then(response => {
      if (response.status === 200) {
        return response.json();
      } else {
        console.log('Something happened wrong');
      }
    })
    .then(resultJson => {
      var content = resultJson.values[0].content[0].maincontent;
      var lessons = resultJson.values[0].scontent;
      var contentLesson = [];
      if (lessons.length > 0) {
        lessons.forEach(lesson => {
          var contentls = lesson.subcontent;
          contentls.id = lesson.subdocid;
          contentLesson.push(contentls);
        });                
      }

      this.setState({
        id: resultJson.values[0].docid,
        title: content.libraryTitle,
        category: content.categoryName,
        excerpt: content.libraryShortDesc,
        image: content.libraryPicture,
        link: '/library/' + resultJson.values[0].docid,
        lesson: contentLesson,
        isLoad: true
      });
      
      
    });
  }
  
  render() {
    return (
      <div className="library-detail">
      {this.state.isLoad=== true? 
        <Container>
          <div className="row align-items-start mx-0">
            <div className="col-md-5 bg-light p-5 br-8">
              <div className="row">
                <div className="col">
                  <h4>Library</h4>                
                </div>
                <div className="col-auto text-end">              
                  <p className="text-red mt-1"><strong>{this.state.category}</strong></p>
                </div>
              </div>
              <div className="text-center">
                {(this.state.image !== "" && this.state.image !== undefined) && <img className="mxw br-8" style={{maxHeight:'350px'}} src={this.state.image} alt={this.state.title}/> }
                <h5 className="my-4">{this.state.title}</h5>
                <p>{this.state.excerpt}</p>
                <a href={"/login/?redirect=" + encodeURIComponent("library/"+this.state.id)} className="btn btn-lg btn-primary mb-3">{GetText() ? "Daftar Sekarang!" : "Enroll Now!"}</a>
              </div>
            </div>
            <div className="col-md-7 agenda p-5 br-8">
              {
                this.state.lesson.length > 0 ? (
                  <div>
                    <h4 className="mb-4">{GetText() ? "Daftar Modul" : "Modules"}</h4>
                    {
                      this.state.lesson
                      .sort((a, b) => a.lessonNumber - b.lessonNumber)
                      .map((lesson, i) => {
                        return (
                          <div className="row lesson-item" key={i}>
                            <div className="col-md-3">
                              <label className="label-rounded-2">{GetText() ? "Modul " + (i + 1) : "Module " + (i + 1)}</label>
                            </div>
                            <div className="col-md-9 text-start">
                              <h5>{lesson.lessonTitle}</h5>
                              <p className="clamp-3">{parse(ReplaceText(lesson.lessonDesc))}</p>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                ): ''
              }
            </div>
          </div>
        </Container>
      : <Loading value={{id:'library-area', type: 'screen'}}/>
      }
      </div>
    );
  }
}