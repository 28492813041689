import React, { Component } from 'react';
import { Container, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import MenuLeftCMS from './ct-fragmentsidemenu';
import { GetText } from '../config/helper';
import '../config/global';
import CTNA from './ct-fragmentna';
import { FormErrors } from '../inc/error-form';
import Error404 from '../Error404';

const authKey = global.config.kindcode.training[6]+global.config.authBearerKey;

export default class CTCompanyTrainingsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activelink:"none",
      stTitle: '',
      company_docid: '',
      company_name: '',
      company_domain: '',
      company_logo: '',
      company_logo_shape: '',
      expiry_date: new Date(),
      new_expiry: false,
      selectedFileDocument: null,
      formErrors: {company_name: '', company_domain: '', expiry_date: '', selectedFileDocument:'', company_logo_shape: ''},
      companynameValid: false,
      companydomainValid: false,
      expirydateValid: false,
      selectedFileDocumentValid: false,
      companylogoshapeValid: false,
      formValid: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleBack = (currpage) => {
    window.location.href=global.config.urlCms+"/"+currpage;
  }
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
        [name]: value
    }, () => {
        this.validateField(name, value)
    });
  }    
  handleInputDate = date => {
    var newExpiry = false;
    if (date !== this.state.expiry_date) {
      newExpiry = true;
    }
    this.setState(prevState => {
      return {  
        ...prevState.expiry_date, expiry_date: date,
        ...prevState.new_expiry, new_expiry: newExpiry
      }
    }, () => { 
      this.validateField('expiry_date', date);
    }); 
  }
  handleUserSelect = (e) => {
    const name = e.target.name; 
    const value = e.target.value;
    this.setState(prevState => {
        return {  
            ...prevState[name], [name]: value
        }
    }, () => { 
        this.validateField(name, value)
    });
  }
  onChangeFileHandler = (e) => {
    if(e.target.files[0] !== undefined) {
      var inputType = e.target.files[0].type;
      var inputValue = e.target.files[0];
      var name = ''; 
      var validname = '';
      let fieldValidationErrors = this.state.formErrors;
      let selectedFileValid = false;
      if(inputType.indexOf('pdf') > -1 || inputType.indexOf('video') > -1|| inputType.indexOf('image') > -1) { //add image
        selectedFileValid = true;
      } else {
        selectedFileValid = false;
      }
      name =  'selectedFileDocument';
      validname = 'selectedFileDocumentValid';
      fieldValidationErrors.selectedFileDocument = selectedFileValid ? '' : 'please upload image only';
      this.setState({
        [name]: inputValue,
        formErrors: fieldValidationErrors,
        [validname] : selectedFileValid
      }, this.validateForm);
    }
  }
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let companynameValid = this.state.companynameValid;
    let companydomainValid = this.state.companydomainValid;
    let expirydateValid = this.state.expirydateValid;
    // let selectedFileDocumentValid = this.state.selectedFileDocumentValid;
    let companylogoshapeValid = this.state.companylogoshapeValid;
    switch(fieldName) {
        case 'company_name': 
          companynameValid = value.trim().length > 0;
          fieldValidationErrors.company_name = companynameValid ? '' : 'company name need to be filled';
          break;
        case 'company_domain': 
          companydomainValid = value.trim().length > 0;
          fieldValidationErrors.company_domain = companydomainValid ? '' : 'company domain need to be filled';
          break;
        case 'company_logo_shape': 
          if(this.state.selectedFileDocument !== "" && this.state.selectedFileDocument !== null) {
            companylogoshapeValid = value.trim().length > 0;
            fieldValidationErrors.company_logo_shape = companylogoshapeValid ? '' : 'shape of company logo need to be filled';
          }
          break;
        default:
            break;
    }    
    this.setState({
        formErrors: fieldValidationErrors,
        companynameValid: companynameValid,
        companydomainValid: companydomainValid,
        expirydateValid: expirydateValid,
        // selectedFileDocumentValid: selectedFileDocumentValid,
        companylogoshapeValid: companylogoshapeValid
    }, this.validateForm);
  }
  validateForm() {
    this.setState({
      formValid: this.state.companynameValid && this.state.companydomainValid && this.state.expirydateValid && this.state.companylogoshapeValid 
    });
  }
  errorClass(error) {
    return(error.length === 0 ? '' : 'has-error');
  }

  async uploadFiles() {
    console.log("aaa");
    try {
      var selectedFileValue = this.state.selectedFileDocument;
      if(selectedFileValue !== null && ((selectedFileValue.type.indexOf('pdf') > -1 || selectedFileValue.type.indexOf('video') > -1|| selectedFileValue.type.indexOf('image') > -1))) { 
        const data = new FormData();
        data.append('file', selectedFileValue);
        data.append('docid', this.state.company_docid);
        data.append('source', 'company');
        const response = await axios.post(global.config.urlLink+"/uploadfile", 
        data, { 
          headers: { 
            'content-type': 'multipart/form-data',
            'Authorization': authKey
          }
        });
        return response.data.values.linkfile1;
      } else {
        return '';
      }
    }
    catch (error) {
      console.log(error);
      return '';
    }
  }
  async handleSubmit(e) {
    console.log("bbb");
    e.preventDefault();
    if(this.state.company_name !== '' && this.state.company_domain !== '' && this.state.expiry_date !== '') {
        var returnUploadFiles;
        if(this.state.selectedFileDocument !== "") { returnUploadFiles = await this.uploadFiles(); }
        else { returnUploadFiles = ""; }
        if(returnUploadFiles !== undefined) {
          var urlFetch, datatext;
          var companyName,companyDomain,companyLogoShape,expiryDate;
          if(this.textInputCompanyName.value === '') { companyName = this.state.company_name; } else { companyName = this.textInputCompanyName.value; }
          if(this.textInputCompanyDomain.value === '') { companyDomain = this.state.company_domain; } else { companyDomain = this.textInputCompanyDomain.value; }
          if(this.textInputCompanyLogoShape.value === '') { companyLogoShape = this.state.company_logo_shape; } else { companyLogoShape = this.textInputCompanyLogoShape.value; }
          expiryDate = this.state.expiry_date;
          
          if(this.state.company_docid !== null && this.state.company_docid !== ''  && this.state.company_docid !== undefined) {
            if(this.state.new_expiry === true) {
              datatext ='{"company_docid":"'+this.state.company_docid+'","company_name":"'+companyName+'","company_domain":"'+companyDomain+'","expiry_date":"'+expiryDate+'","company_logo":"'+returnUploadFiles+'","company_logo_shape":"'+companyLogoShape+'","form_id":"form-update-all"}';
            } else {
              datatext ='{"company_docid":"'+this.state.company_docid+'","company_name":"'+companyName+'","company_domain":"'+companyDomain+'","expiry_date":"'+expiryDate+'","company_logo":"'+returnUploadFiles+'","company_logo_shape":"'+companyLogoShape+'","form_id":"form-edit"}';
            }
              urlFetch = global.config.urlLink+"/trainingcompanyedit";
          } else {
              datatext ='{"company_name":"'+companyName+'","company_domain":"'+companyDomain+'","expiry_date":"'+expiryDate+'","company_logo":"'+returnUploadFiles+'","company_logo_shape":"'+companyLogoShape+'","form_id":"form-add"}';
              urlFetch = global.config.urlLink+"/trainingcompanyadd";
          }
          var data = JSON.parse(datatext);
          fetch(urlFetch, {
              method: 'POST',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': '*',
                  'Authorization': authKey,
              }, body : JSON.stringify(data)
          }).then(res => {
              if(res.status === 200)
                  return res.json() 
          }).then( resJson => {
              if(resJson.status === '200') {
                  var docId = resJson.values;
                  window.location.href=global.config.urlCms+"/"+this.state.activelink+"/view/"+docId;
              } else {
                  console.log('Something happened wrong');
              }
              document.getElementById('loading-area').style.display = 'none'; 
              if(resJson.status === '200') {
              } else {
                  console.log('Something happened wrong');
              }
          }).catch(err => { 
              document.getElementById('loading-area').style.display = 'none'; 
          });
        }
    } else {
        var arrfieldName = ['company_name','company_domain','expiry_date','company_logo_shape','selectedFileDocument'];
        var arrvalue = [this.textInputCompanyName.value,this.textInputCompanyDomain.value,this.state.expiry_date,this.textInputCompanyLogoShape.value,this.state.selectedFileDocument];
        for(var ck in arrfieldName) {
            this.validateField(arrfieldName[ck], arrvalue[ck]);
        }
    }
  }

  loadData(currpage,typelink,docid) {
    if(typelink === 'edit') {
        if(docid !== '') { 
            fetch(global.config.urlLink+"/trainingcompanyview/"+docid, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }
                }).then(res => {
                if(res.status === 200) 
                    return res.json() 
                }).then( resJson => {
                  console.log(resJson);
                  if(resJson.status === '200') {
                    if(resJson.values[0].content[0].maincontent !== undefined) {
                    
                      this.setState({
                          activelink: currpage,
                          stTitle: 'Edit Company',
                          company_docid: resJson.values[0].docid,
                          company_name: resJson.values[0].content[0].maincontent.companyName,
                          company_domain: resJson.values[0].content[0].maincontent.companyDomain,
                          company_logo: resJson.values[0].content[0].maincontent.companyLogo,
                          company_logo_shape: resJson.values[0].content[0].maincontent.companyLogoShape,
                          expiry_date: new Date(resJson.values[0].content[0].maincontent.expiredSubscription._seconds * 1000),
                      }) 
                    } else {
                      window.location.href=global.config.urlCms+"/"+currpage+"/add";
                    }
                  } else {
                    console.log('Something wrong happened');
                  }
                }).catch(err => err);
        } else {
          this.setState( prevState => {
            return {
              ...prevState.activelink, activelink: currpage,
              ...prevState.stTitle, stTitle: 'Edit Company'
            }
          })
        }
    } else if(typelink === 'add') {
        this.setState( prevState => {
            return {
              ...prevState.activelink, activelink: currpage,
              ...prevState.stTitle, stTitle: 'New Company'
            }
        })
    }    
  }
  componentDidMount() {
    var urlpath = window.location.pathname;
    if(urlpath.substring(urlpath.length-1,urlpath.length) === "/") {
      urlpath = urlpath.substring(0,urlpath.length-1);
    }
    var arrUrl = urlpath.split("/");
    
    var tabKey="", tabKey2="", docId="";
    if(arrUrl.length===4 && arrUrl[arrUrl.length-1] !== "") {
      tabKey = arrUrl[arrUrl.length-2];
      tabKey2 = arrUrl[arrUrl.length-1];
    } else if(arrUrl.length===5 && arrUrl[arrUrl.length-1] !== "") {
        tabKey = arrUrl[arrUrl.length-3];
        tabKey2 = arrUrl[arrUrl.length-2];
        docId = arrUrl[arrUrl.length-1];
    }
    if(tabKey === "") {
      this.setState({
          activelink: "pnf",
          stTitle : (tabKey2 === "add")? "Add " : (tabKey2 === "edit") ? "Edit " : ""
        });
    } else {
      this.loadData(tabKey,tabKey2,docId);
    }
    
  }

  render() {
    return (
      <div className="setting">
       <Container>
        { (this.state.activelink === "pnf")? 
          <Error404 />
          : 
          (localStorage.getItem("ulvl") === global.config.userLevel[0] || localStorage.getItem("ulvl") === global.config.userLevel[2])? 
          (<>
            <div className="row py-5 mb-5">
              {/* === start - body */}
                <div className="col-md-3 bg-light">
                  { 
                    (this.state.activelink !== "none") && 
                      <MenuLeftCMS value={{tabkey:this.state.activelink}}/>
                  
                  }
                </div>
                <div className="col-md-9 p-4">
                  <div className="row">
                    <div>
                      <h3 className="mb-4">{GetText() ? " Perusahaan" : " Companies"}</h3>
                    </div>
                    <div className="mt-3">
                      <div className="row show">
                          <div className="col-6"><h5>{this.state.stTitle} </h5></div>
                          <div className="col-6 d-flex justify-content-end">
                             <Button variant="secondary" size="sm" onClick={this.handleBack.bind(this, this.state.activelink)}>&#9664; back</Button>
                          </div>
                      </div>
                      <div className="row mt-3">
                            <form onSubmit={this.handleSubmit}>
                                <div >
                                    <input type="hidden" required name="company_docid" value={this.state.company_docid}/>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="company_name">Name</label>
                                    <div className="col-md-9">
                                        <input type="text" className="form-control" name="company_name"
                                            placeholder={this.state.company_name}
                                            value={this.state.company_name}
                                            onChange={this.handleUserInput}  
                                            ref={(input) => this.textInputCompanyName = input}/>
                                        <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.company_name}/>  }</div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="company_domain">Domain</label>
                                    <div className="col-md-9">
                                        <input type="text" className="form-control" name="company_domain"
                                            placeholder={this.state.company_domain}
                                            value={this.state.company_domain}
                                            onChange={this.handleUserInput}  
                                            ref={(input) => this.textInputCompanyDomain = input}/>
                                        <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.company_domain}/>  }</div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="expiry_date">Expiry Date</label>
                                    <div className="col-md-9">
                                    <DatePicker
                                          onChange={this.handleInputDate}
                                          value={this.state.expiry_date}
                                          selected={this.state.expiry_date}
                                          dateFormat="MMMM d, yyyy"
                                          //style={{border:'none'}}
                                          // customStyles={{dateInput:{borderWidth: 0}}}
                                          className="form-control"
                                          />
                                      
                                        <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.expiry_date}/>  }</div>
                                    </div>
                                </div>
                                <div>
                                  {
                                    (this.state.company_logo !== "") && (
                                      <div className="row-field mgT20">
                                        <div className="labelFormL"></div>
                                        <div className="w70p"><img width="150" src={this.state.company_logo} alt=""/></div>
                                      </div>
                                    )    
                                  }
                                </div>
                                <div className="row mt-3">
                                  <label className="col-md-3" htmlFor="company_logo">Company Logo</label>
                                  <div className="col-md-9">
                                      <input id="fileDocument" type="file" onChange={this.onChangeFileHandler}></input> 
                                      <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.selectedFileDocument}/>  }</div>
                                      <div className="mt-2 fieldComments" >&bull; Please upload file in jpg, jpeg, png format.</div>
                                  </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="company_logo_shape">Logo will be shown in</label>
                                    <div className="col-md-9">
                                        <select name="company_logo_shape" className="form-control" 
                                            value={this.state.company_logo_shape} 
                                            onChange={this.handleUserSelect}
                                            ref={(input) => this.textInputCompanyLogoShape = input}
                                        >
                                            <option value="" key="">-- please select --</option>
                                            <option value="round" key="1">Round Shape</option>
                                            <option value="rectangle" key="2">Rectangle Shape</option>
                                        </select>
                                        <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.company_logo_shape}/>  }</div>
                                    </div>
                                </div>
                                <div className="row mt-4 mb-3">
                                    <div className="col-md-3"></div>
                                    <div className="col-md-9"><Button type="submit" variant="secondary">Submit</Button></div>
                                </div>
                            </form>
                      </div>
                    </div>
                  </div>
                </div>
              {/* === end - body */}
            </div>
            
            </>
          )
        : 
          <div><CTNA/></div>
        }
        <div id="loading-area" className="d-none loading"></div> 
        </Container>
      </div>
    );
  }
}
