import React, { Component } from 'react';
import axios from 'axios';
import { Container, Button } from 'react-bootstrap';
import MenuLeftCMS from './ct-fragmentsidemenu';
import { GetText } from '../config/helper';
import '../config/global';
import CTNA from './ct-fragmentna';
import { FormErrors } from '../inc/error-form';
import Error404 from '../Error404';
import ReplaceAllContent from '../config/replace-all';

const authKey = global.config.kindcode.training[4]+global.config.authBearerKey;

export default class CTTutorForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activelink:"none",
      stTitle: '',
      tutor_docid: '',
      tutor_name: '',
      tutor_email: '',
      tutor_title: '',
      tutor_bio: '',
      tutor_linkedin: '',
      tutor_picture: '',
      tutor_status: '',
      selectedFileDocument: null,
      formErrors: {tutor_name: '', tutor_email: '', tutor_title: '', tutor_bio: '', tutor_status: '', selectedFileDocument:''},
      tutornameValid: false,
      tutoremailValid: false,
      tutortitleValid: false,
      tutorbioValid: false,
      tutorstatusValid: false,
      selectedFileDocumentValid: false,
      formValid: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.submitData = this.submitData.bind(this);
  }
  handleBack = (currpage) => {
    // this.props.history.goBack();
    window.location.href=global.config.urlCms+"/"+currpage;
  }
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
        [name]: value
    }, () => {
        this.validateField(name, value)
    });
  }    
  handleUserSelect = (e) => {
    const name = e.target.name; 
    const value = e.target.value;
    this.setState(prevState => {
        return {  
            ...prevState[name], [name]: value
        }
    }, () => { 
        this.validateField(name, value)
    });
  }
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let tutornameValid = this.state.tutornameValid;
    let tutoremailValid = this.state.tutoremailValid;
    let tutortitleValid = this.state.tutortitleValid;
    let tutorbioValid = this.state.tutorbioValid;
    let tutorstatusValid = this.state.tutorstatusValid;
    let selectedFileDocumentValid = this.state.selectedFileDocumentValid;
    switch(fieldName) {
        case 'tutor_name': 
            tutornameValid = value.trim().length > 0;
            fieldValidationErrors.tutor_name = tutornameValid ? '' : 'name need to be filled';
            break;
        case 'tutor_email': 
            tutoremailValid = value.trim().length > 0;
            fieldValidationErrors.tutor_email = tutoremailValid ? '' : 'email need to be filled';
            break;
        case 'tutor_title': 
            tutortitleValid = value.trim().length > 0;
            fieldValidationErrors.tutor_title = tutortitleValid ? '' : 'academic / job title need to be filled';
            break;
        case 'tutor_bio': 
            tutorbioValid = value.trim().length > 0;
            fieldValidationErrors.tutor_bio = tutorbioValid ? '' : 'bio need to be filled';
            break;
        case 'tutor_status': 
            tutorstatusValid = value.trim().length > 0;
            fieldValidationErrors.tutor_status = tutorstatusValid ? '' : 'status need to be filled';
            break;
        default:
            break;
    }    
    this.setState({
        formErrors: fieldValidationErrors,
        tutornameValid: tutornameValid,
        tutoremailValid: tutoremailValid,
        tutortitleValid: tutortitleValid,
        tutorbioValid: tutorbioValid,
        tutorstatusValid: tutorstatusValid,
        selectedFileDocumentValid: selectedFileDocumentValid
    }, this.validateForm);
  }
  vvalidateForm() {
    this.setState({
        formValid: this.state.tutornameValid && this.state.tutoremailValid && this.state.tutortitleValid && this.state.tutorbioValid && this.state.tutorstatusValid && 
        this.state.selectedFileDocumentValid
    });
  }
  errorClass(error) {
    return(error.length === 0 ? '' : 'has-error');
  }
  onChangeFileHandler = (e) => {
    if(e.target.files[0] !== undefined) {
        var inputType = e.target.files[0].type;
        var inputValue = e.target.files[0];
        var name = ''; 
        var validname = '';
        let fieldValidationErrors = this.state.formErrors;
        let selectedFileValid = false;
        if(inputType.indexOf('pdf') > -1 || inputType.indexOf('video') > -1|| inputType.indexOf('image') > -1) { //add image
            selectedFileValid = true;
        } else {
            selectedFileValid = false;
        }
        if(e.target.id === "fileDocument2") {
            name =  'selectedFileDocument2';
            validname = 'selectedFileDocument2Valid';
            fieldValidationErrors.selectedFileDocument2 = selectedFileValid ? '' : 'please upload video only';
        } else {
            name =  'selectedFileDocument';
            validname = 'selectedFileDocumentValid';
            fieldValidationErrors.selectedFileDocument = selectedFileValid ? '' : 'please upload image only';
        }        
        this.setState({
            [name]: inputValue,
            formErrors: fieldValidationErrors,
            [validname] : selectedFileValid
        }, this.validateForm);
    }
  }
  submitData(datasend,imgurl) {
    var urlFetch, datatext;
    var tutorName,tutorEmail,tutorTitle,tutorBio,tutorLinkedin,tutorPicture,tutorStatus;
    if(imgurl !== '' && imgurl !== undefined) { tutorPicture = imgurl; } else { tutorPicture = this.state.tutor_picture; }
    if(this.state.tutor_docid !== null && this.state.tutor_docid !== ''  && this.state.tutor_docid !== undefined) { 
        if(this.textInputTutorName.value === '') { tutorName = this.state.tutor_name; } else { tutorName = this.textInputTutorName.value; }
        if(this.textInputTutorEmail.value === '') { tutorEmail = this.state.tutor_email; } else { tutorEmail = this.textInputTutorEmail.value; }
        if(this.textInputTutorTitle.value === '') { tutorTitle = this.state.tutor_title; } else { tutorTitle = this.textInputTutorTitle.value; }
        if(this.textInputTutorBio.value === '') { tutorBio = this.state.tutor_bio; } else { tutorBio = this.textInputTutorBio.value; }
        if(this.textInputTutorLinkedin.value === '') { tutorLinkedin = this.state.tutor_linkedin; } else { tutorLinkedin = this.textInputTutorLinkedin.value; }
        if(this.textInputTutorStatus.value === '') { tutorStatus = this.state.tutor_status; } else { tutorStatus = this.textInputTutorStatus.value; }
        tutorBio = ReplaceAllContent('\n','<br/>',ReplaceAllContent(',','[;]',tutorBio));
        tutorStatus = tutorStatus === 'Active' ? 1 : 0;
        datatext ='{"tutor_name":"'+tutorName+'","tutor_email":"'+tutorEmail+'","tutor_title":"'+tutorTitle+'","tutor_bio":"'+tutorBio+'","tutor_linkedin":"'+tutorLinkedin+'","tutor_picture":"'+tutorPicture+'","tutor_status":"'+tutorStatus+'","tutor_docid":"'+this.state.tutor_docid+'","form_id":"form-edit"}';
        urlFetch = global.config.urlLink+"/tutoredit";
    } else {
        tutorStatus = tutorStatus === 'Active' ? 1 : 0;
        tutorBio = ReplaceAllContent('\n','<br/>',ReplaceAllContent(',','[;]',this.state.tutor_bio));
        datatext ='{"tutor_name":"'+this.state.tutor_name+'","tutor_email":"'+this.state.tutor_email+'","tutor_title":"'+this.state.tutor_title+'","tutor_bio":"'+tutorBio+'","tutor_linkedin":"'+this.state.tutor_linkedin+'","tutor_picture":"'+tutorPicture+'","tutor_status":"'+tutorStatus+'","form_id":"form-add"}';
        urlFetch = global.config.urlLink+"/tutoradd";
    }
    var data = JSON.parse(datatext);
    fetch(urlFetch, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
    }).then(res => {
        if(res.status === 200) 
            return res.json() 
    }).then( resJson => {
        if(resJson.status === '200') {
            var docId = resJson.values;
            window.location.href=global.config.urlCms+"/"+this.state.activelink+"/view/"+docId;
            // window.location.href=global.ctconfig.urlHost+'/cteditdetailtutor/'+docId;
        } else {
            console.log('Something happened wrong');
        }

        document.getElementById('loading-area').style.display = 'none'; 
        if(resJson.status === '200') {
        } else {
            console.log('Something happened wrong');
        }
    }).catch(err => { 
        document.getElementById('loading-area').style.display = 'none'; 
    });
  }
  handleSubmit(e) {
    e.preventDefault();   
    if(this.state.tutor_name !== '' && this.state.tutor_email !== '' && this.state.tutor_title !== '' && this.state.tutor_bio !== '' && this.state.tutor_status !== '' ) {
        document.getElementById('loading-area').style.display = 'block'; 
        var selectedFileValue = this.state.selectedFileDocument;
        if(selectedFileValue !== null && ((selectedFileValue.type.indexOf('pdf') > -1 || selectedFileValue.type.indexOf('video') > -1|| selectedFileValue.type.indexOf('image') > -1))) { 
            const data = new FormData() 
            data.append('file', selectedFileValue);
            data.append('docid', this.state.tutor_docid);
            data.append('source', 'tutor');
            axios.post(global.ctconfig.urlLink+"/uploadfile", 
            data, { 
                headers: { 
                    'content-type': 'multipart/form-data',
                    'Authorization': authKey
                }
            }).then(res => { 
                document.getElementById('loading-area').style.display = 'none'; 
                if(res.data.status === "200") {
                    this.submitData(this,res.data.values.linkfile1);
                } else {
                    document.getElementById('loading-area').style.display = 'none'; 
                }
            }).catch(err => { 
                document.getElementById('loading-area').style.display = 'none'; 
            })
        } else {
            document.getElementById('loading-area').style.display = 'none'; 
            this.submitData(this,"","");
        }
    
    } else {
        var arrfieldName = ['tutor_name','tutor_email','tutor_title','tutor_bio','tutor_linkedin','tutor_status','selectedFileDocument'];
        var arrvalue = [this.textInputTutorName.value,this.textInputTutorEmail.value,this.textInputTutorTitle.value,this.textInputTutorBio.value,this.textInputTutorLinkedin.value,this.textInputTutorStatus.value,this.state.selectedFileDocument];
        for(var ck in arrfieldName) {
            this.validateField(arrfieldName[ck], arrvalue[ck]);
        }
    }
    
  }
  

  loadData(currpage,typelink,docid) {
    if(typelink === 'edit') {
        if(docid !== '') { 
            document.getElementById('loading-area').style.display = 'block';
            fetch(global.config.urlLink+"/tutorview/"+docid, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }
                }).then(res => {
                if(res.status === 200) 
                    return res.json() 
                }).then( resJson => {
                  document.getElementById('loading-area').style.display = 'none';
                  if(resJson.status === '200') {
                    if(resJson.values[0].content[0].maincontent !== undefined) {
                    
                      var resultContent = resJson.values[0].content[0].maincontent;
                      var lName="",lEmail="",lTitle="",lBio="",lLinkedin="",lPicture="",lStatus="";
                      
                      if(resultContent.tutorName !== undefined) { lName = resultContent.tutorName; }
                      if(resultContent.tutorEmail !== undefined) { lEmail = resultContent.tutorEmail; }
                      if(resultContent.tutorTitle !== undefined) { lTitle = resultContent.tutorTitle; }
                      if(resultContent.tutorBio !== undefined) {
                          lBio = ReplaceAllContent('<br/>','\n',ReplaceAllContent('[;]',',',resultContent.tutorBio));
                      }
                      if(resultContent.tutorLinkedin !== undefined) { lLinkedin = resultContent.tutorLinkedin; }
                      if(resultContent.tutorPicture !== undefined) { lPicture = resultContent.tutorPicture; }
                      if(resultContent.tutorStatus !== undefined) { lStatus = resultContent.tutorStatus === 1 ? 'Active' : 'Inactive'; }
                      this.setState({
                          activelink: currpage,
                          stTitle: GetText() ? "Edit Pelatih" : "Edit Trainer",
                          tutor_docid: resJson.values[0].docid,
                          tutor_name: lName,
                          tutor_email: lEmail,
                          tutor_title: lTitle,
                          tutor_bio: lBio,
                          tutor_linkedin: lLinkedin,
                          tutor_picture: lPicture,
                          tutor_status: lStatus,
                          selectedFileDocument: null,
                          formErrors: {tutor_name: '', tutor_email: '', tutor_title: '', tutor_bio: '', tutor_status: '', selectedFileDocument:''},
                          tutornameValid: false,
                          tutoremailValid: false,
                          tutortitleValid: false,
                          tutorbioValid: false,
                          tutorstatusValid: false,
                          selectedFileDocumentValid: false,
                          formValid: false,
                      }) 
                    } else {
                      window.location.href=global.config.urlCms+"/"+currpage+"/add";
                    }
                  } else {
                    console.log('Something wrong happened');
                  }
                }).catch(err => err);
        } else {
          this.setState( prevState => {
            return {
              ...prevState.activelink, activelink: currpage,
              ...prevState.stTitle, stTitle:  GetText() ? "Edit Pelatih" : "Edit Trainer",
            }
          })
        }
    } else if(typelink === 'add') {
        this.setState( prevState => {
            return {
              ...prevState.activelink, activelink: currpage,
              ...prevState.stTitle, stTitle:  GetText() ? "Tambah Pelatih" : "Add Trainer",
            }
        })
    }    
  }
  componentDidMount() {
    var urlpath = window.location.pathname;
    if(urlpath.substring(urlpath.length-1,urlpath.length) === "/") {
      urlpath = urlpath.substring(0,urlpath.length-1);
    }
    var arrUrl = urlpath.split("/");
    
    var tabKey="", tabKey2="", docId="";
    if(arrUrl.length===4 && arrUrl[arrUrl.length-1] !== "") {
      tabKey = arrUrl[arrUrl.length-2];
      tabKey2 = arrUrl[arrUrl.length-1];
    } else if(arrUrl.length===5 && arrUrl[arrUrl.length-1] !== "") {
        tabKey = arrUrl[arrUrl.length-3];
        tabKey2 = arrUrl[arrUrl.length-2];
        docId = arrUrl[arrUrl.length-1];
    }
    if(tabKey === "") {
      this.setState({
          activelink: "pnf",
          stTitle : (tabKey2 === "add")? "Add " : (tabKey2 === "edit") ? "Edit " : ""
        });
    } else {
      this.loadData(tabKey,tabKey2,docId);
    }
    
  }

  render() {
    return (
      <div className="setting">
       <Container>
        { (this.state.activelink === "pnf")? 
          <Error404 />
          : 
          (localStorage.getItem("ulvl") === global.config.userLevel[0] || localStorage.getItem("ulvl") === global.config.userLevel[2])? 
          (<>
            <div className="row py-5 mb-5">
              {/* === start - body */}
                <div className="col-md-3 bg-light">
                  { 
                    (this.state.activelink !== "none") && 
                      <MenuLeftCMS value={{tabkey:this.state.activelink}}/>
                  
                  }
                </div>
                <div className="col-md-9 p-4">
                  <div className="row">
                    <div>
                      <h3 className="mb-4">{GetText() ? " Pelatih" : " Trainers"}</h3>
                    </div>
                    <div className="mt-3">
                      <div className="row show">
                          <div className="col-6"><h5>{this.state.stTitle} </h5></div>
                          <div className="col-6 d-flex justify-content-end">
                             <Button variant="secondary" size="sm" onClick={this.handleBack.bind(this, this.state.activelink)}>&#9664; back</Button>
                          </div>
                      </div>
                      <div className="row mt-3">
                            <form onSubmit={this.handleSubmit}>
                                <div >
                                    <input type="hidden" required name="tutor_docid" value={this.state.tutor_docid}/>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="tutor_name">Name</label>
                                    <div className="col-md-9">
                                        <input type="text" className="form-control" name="tutor_name"
                                                placeholder={this.state.tutor_name}
                                                value={this.state.tutor_name}
                                                onChange={this.handleUserInput}  
                                                ref={(input) => this.textInputTutorName = input}/>
                                        <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.tutor_name}/>  }</div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="tutor_email">Email</label>
                                    <div className="col-md-9">
                                        <input type="text" className="form-control" name="tutor_email"
                                                placeholder={this.state.tutor_email ? this.state.tutor_email : ''}
                                                value={this.state.tutor_email ? this.state.tutor_email : ''}
                                                onChange={this.handleUserInput}
                                                ref={(input) => this.textInputTutorEmail = input}/>
                                        <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.tutor_email}/>  }</div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="tutor_title">Academic / Job Title</label>
                                    <div className="col-md-9">
                                        <input type="text" className="form-control" name="tutor_title"
                                                placeholder={this.state.tutor_title ? this.state.tutor_title : ''}
                                                value={this.state.tutor_title ? this.state.tutor_title : ''}
                                                onChange={this.handleUserInput}
                                                ref={(input) => this.textInputTutorTitle = input}/>
                                        <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.tutor_title}/>  }</div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="tutor_bio">Biography</label>
                                    <div className="col-md-9">
                                        <textarea name="tutor_bio" className="form-control" 
                                                rows={5} cols={30} 
                                                value={this.state.tutor_bio} 
                                                onChange={this.handleUserInput} 
                                                ref={(input) => this.textInputTutorBio = input}/>
                                        <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.tutor_bio}/>  }</div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="tutor_linkedin">Linked In</label>
                                    <div className="col-md-9">
                                        <input type="text" className="form-control" name="tutor_linkedin"
                                                placeholder={this.state.tutor_linkedin ? this.state.tutor_linkedin : ''}
                                                value={this.state.tutor_linkedin ? this.state.tutor_linkedin : ''}
                                                onChange={this.handleUserInput}
                                                ref={(input) => this.textInputTutorLinkedin = input}/>
                                    </div>
                                </div>
                                {(this.state.tutor_picture !== "") && 
                                <div className="row mt-3">
                                        <div className="col-md-3"></div>
                                        <div className="col-md-9"><img width="150" src={this.state.tutor_picture} alt=""/></div>
                                </div>
                                }
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="tutor_document">Upload Picture</label>
                                    <div className="col-md-9">
                                        <input id="fileDocument" type="file"
                                            onChange={this.onChangeFileHandler}>
                                        </input> 
                                        <div className="mt-2">{ <FormErrors formErrors={this.state.formErrors.selectedFileDocument}/>  }</div>
                                        <div className="mt-2 fieldComments">
                                        &bull; Please upload file in jpg, jpeg, png format.
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="tutor_status">Status</label>
                                    <div className="col-md-9">
                                        <select name="tutor_status" className="form-control" 
                                            value={this.state.tutor_status} 
                                            onChange={this.handleUserSelect}
                                            ref={(input) => this.textInputTutorStatus = input}
                                            disabled={this.state.input_disabled}
                                        >
                                            <option value="" key="">-- please select --</option>
                                            <option value="Active" key="status_active">Active</option>
                                            <option value="Inactive" key="status_inactive">Inactive</option>
                                        </select>
                                        <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.tutor_status}/>  }</div>
                                    </div>
                                </div>
                                <div className="row mt-4 mb-3">
                                    <div className="col-md-3"></div>
                                    <div className="col-md-9"><Button type="submit" variant="secondary">Submit</Button></div>
                                </div>
                            </form>
                      </div>
                    </div>
                  </div>
                </div>
              {/* === end - body */}
            </div>
            
            </>
          )
        : 
          <div><CTNA/></div>
        }
        <div id="loading-area" className="d-none loading"></div> 
        </Container>
      </div>
    );
  }
}
