import React, { Component } from 'react';
import { Container, Button } from 'react-bootstrap';
import parse from 'html-react-parser';
import MenuLeftCMS from './ct-fragmentsidemenu';
import { GetText } from '../config/helper';
import '../config/global';
import CTNA from './ct-fragmentna';
import Error404 from '../Error404';

const authKey = global.config.kindcode.training[16]+global.config.authBearerKey;

export default class CTFaqCourseDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activelink:"none",
      stTitle: '',
      faq_course_id: '',
      faq_course_number: '',
      faq_course_question: '',
      faq_course_answer: '',
      faq_course_language: '',
      faq_course_status: '',
    }
    this.loadDetailData = this.loadDetailData.bind(this);
  }
  handleBack = (currpage) => {
    // this.props.history.goBack();
    window.location.href=global.config.urlCms+"/"+currpage;
  }
  
  loadDetailData(currpage,typelink,docid) {
        if(docid !== '') { 
            document.getElementById('loading-area').style.display = 'block';
            fetch(global.config.urlLink+"/faqcourseview/"+docid, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }
                }).then(res => {
                if(res.status === 200) 
                    return res.json() 
                }).then( resJson => {
                  if(resJson.status === '200') {
                    if(resJson.values[0].content[0].maincontent !== undefined) {
                    
                      var resultContent = resJson.values[0].content[0].maincontent;
                      this.setState({
                          activelink: currpage,
                          stTitle: 'Detail FAQ',
                          faq_course_id: resJson.values[0].docid,
                          faq_course_number: resultContent.faqCourseNumber,
                          faq_course_question: resultContent.faqCourseQuestion,
                          faq_course_answer: resultContent.faqCourseAnswer,
                          faq_course_language: resultContent.faqCourseLanguage,
                          faq_course_status: resultContent.faqCourseStatus,
                      }); 
                      document.getElementById('loading-area').style.display = 'none';
                    } else {
                      window.location.href=global.config.urlCms+"/"+currpage+"/add";
                    }
                  } else {
                    console.log('Something wrong happened');
                    document.getElementById('loading-area').style.display = 'none';
                  }
                }).catch(err => err);
        } else {
          window.location.href=global.config.urlCms+"/"+currpage+"/add";
        }
  }
  componentDidMount() {
    var urlpath = window.location.pathname;
    if(urlpath.substring(urlpath.length-1,urlpath.length) === "/") {
      urlpath = urlpath.substring(0,urlpath.length-1);
    }
    var arrUrl = urlpath.split("/");
    
    var tabKey="", tabKey2="", docId="";
    if(arrUrl.length===4 && arrUrl[arrUrl.length-1] !== "") {
      tabKey = arrUrl[arrUrl.length-2];
      tabKey2 = arrUrl[arrUrl.length-1];
    } else if(arrUrl.length===5 && arrUrl[arrUrl.length-1] !== "") {
        tabKey = arrUrl[arrUrl.length-3];
        tabKey2 = arrUrl[arrUrl.length-2];
        docId = arrUrl[arrUrl.length-1];
    }
    if(tabKey === "") {
      this.setState({
          activelink: "pnf",
          stTitle : (tabKey2 === "view")? "Detail FAQ" : ""
        });
    } else {
      this.loadDetailData(tabKey,tabKey2,docId);
    }
    
  }

  render() {
    return (
      <div className="setting">
       <Container>
        { (this.state.activelink === "pnf")? 
          <Error404 />
          : 
          (localStorage.getItem("ulvl") === global.config.userLevel[0] || localStorage.getItem("ulvl") === global.config.userLevel[2])? 
          (<>
            <div className="row py-5 mb-5">
              {/* === start - body */}
                <div className="col-md-3 bg-light">
                  { 
                    (this.state.activelink !== "none") && 
                      <MenuLeftCMS value={{tabkey:this.state.activelink}}/>
                  
                  }
                </div>
                <div className="col-md-9 p-4">
                  <div className="row">
                    <div>
                      <h3 className="mb-4">{GetText() ? "FAQ di Workshop" : "FAQ in Workshop"}</h3>
                    </div>
                    <div className="mt-3">
                      <div className="row show">
                          <div className="col-6">
                            <h5>{this.state.stTitle} #{this.state.faq_course_number}</h5>
                          </div>
                          <div className="col-6 d-flex justify-content-end">
                             <Button variant="secondary" size="sm" onClick={this.handleBack.bind(this, this.state.activelink)}>&#9664; back</Button>
                          </div>
                      </div>
                      <div className="row mt-3">
                          <div className="row mt-3 fieldComments">
                            <label>{this.state.faq_course_language} version</label>
                            <label>Status : {this.state.faq_course_status === 1? "Active": "Inactive"}</label>
                          </div>
                          <div className="row mt-3">
                            <label htmlFor="feedback_text">Question :</label>
                            <div>{this.state.faq_course_question}</div>
                          </div>
                          <div className="row mt-3">
                            <label htmlFor="feedback_text">Answer :</label>
                            <div>{parse(this.state.faq_course_answer)}</div>
                          </div>
                           
                          

                      </div>
                    </div>
                  </div>
                </div>
              {/* === end - body */}
            </div>
            
            </>
          )
        : 
          <div><CTNA/></div>
        }
        <div id="loading-area" className="d-none loading"></div> 
        </Container>
      </div>
    );
  }
}
