import React, { Component } from 'react';
import { Container, Button } from 'react-bootstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import MyCustomUploadAdapterPlugin from '../inc/ckImageUploader';
import MenuLeftCMS from './ct-fragmentsidemenu';
import { GetText } from '../config/helper';
import '../config/global';
import CTNA from './ct-fragmentna';
import { FormErrors } from '../inc/error-form';
import ReplaceAllContent from '../config/replace-all';
import Error404 from '../Error404';

const authKey = global.config.kindcode.training[16]+global.config.authBearerKey;

class CTFaqCourseForm extends Component {
    constructor (props) {
        super(props);
        this.state = {
            activelink:"none",
            tab_language: '',
            faq_course_id: '',
            faq_course_number: '',
            faq_course_question: '',
            faq_course_answer: '',
            faq_course_language: '',
            faq_course_status: '',
            formErrors: { faq_course_number: '', faq_course_question: '', faq_course_answer: '', faq_course_language: '', faq_course_status: ''},
            faqcoursenumberValid: false,
            faqcoursequestionValid: false,
            faqcourseanswerValid: false,
            faqcourselanguageValid: false,
            faqcoursestatusValid: false,
            formValid: false,
            input_disabled: true
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.loadData = this.loadData.bind(this);
    }
    handleBack = (currpage) => {
        window.location.href=global.config.urlCms+"/"+currpage;
    }
    handleUserCKEditorInput = (e) => {
        const name = "faq_course_answer";
        const value = e.getData();
        this.setState({[name]: value},
            () => { this.validateField(name, value)});
    }
    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value},
            () => { this.validateField(name, value)});
    }
    handleUserSelect = (e) => {
        const inputname = e.target.name; 
        const value = e.target.value;
        this.setState(prevState => {
            return {  
                ...prevState[inputname], [inputname]: value
            }
        }, () => { 
            this.validateField(inputname, value)
        });
        
    }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let faqcoursenumberValid = this.state.faqcoursenumberValid;
        let faqcoursequestionValid = this.state.faqcoursequestionValid;
        let faqcourseanswerValid = this.state.faqcourseanswerValid;
        let faqcourselanguageValid = this.state.faqcourselanguageValid;
        let faqcoursestatusValid = this.state.faqcoursestatusValid;
        
        switch(fieldName) {
            case 'faq_course_number': 
                faqcoursenumberValid = value.trim().length > 0;
                fieldValidationErrors.faq_course_number = faqcoursenumberValid ? '' : 'order of to need to be filled';
                break;
            case 'faq_course_question': 
                faqcoursequestionValid = value.trim().length > 0;
                fieldValidationErrors.faq_course_question = faqcoursequestionValid ? '' : 'question need to be filled';
                break;
            case 'faq_course_answer': 
                faqcourseanswerValid = value.trim().length > 0;
                fieldValidationErrors.faq_course_answer = faqcourseanswerValid ? '' : 'answer need to be filled';
                break;
            case 'faq_course_language': 
                faqcourselanguageValid = value.trim().length > 0;
                fieldValidationErrors.faq_course_language = faqcourselanguageValid ? '' : 'language need to be filled';
                break;
            case 'faq_course_status': 
                faqcoursestatusValid = value.trim().length > 0;
                fieldValidationErrors.faq_course_status = faqcoursestatusValid ? '' : 'status need to be filled';
                break;
            default:
                break;
        }
        
        this.setState({formErrors: fieldValidationErrors,
                        faqcoursenumberValid: faqcoursenumberValid,
                        faqcourseanswerValid: faqcourseanswerValid,
                        faqcoursequestionValid: faqcoursequestionValid,
                        faqcourselanguageValid: faqcourselanguageValid,
                        faqcoursestatusValid: faqcoursestatusValid,
                    }, this.validateForm);
    }
    validateForm() {
        this.setState({formValid: this.state.faqcoursenumberValid && this.state.faqcourseanswerValid && this.state.faqcoursequestionValid && this.state.faqcourselanguageValid && this.state.faqcoursestatusValid });
    }

    errorClass(error) {
        return(error.length === 0 ? '' : 'has-error');
    }
    handleSubmit(e) {
        e.preventDefault();
        //alert(JSON.stringify(this.state));
        //alert(this.event_enddate)
        if(this.state.faq_course_number !== '' && this.state.faq_course_question !== '' && this.state.faq_course_answer !== '' && this.state.faq_course_language !== '' && this.state.faq_course_status !== '') {
            document.getElementById('loading-area').style.display = 'block'; 
            var faqCourseNumber, faqCourseAnswer, faqCourseQuestion, faqCourseLanguage, faqCourseStatus;
            if(this.textInputFaqCourseNumber.value === '') { faqCourseNumber = this.state.faq_course_number; } else { faqCourseNumber = this.textInputFaqCourseNumber.value; }
            // if(this.textInputFaqCourseAnswer.value === '') { faqCourseAnswer = this.state.faq_course_answer; } else { faqCourseAnswer = this.textInputFaqCourseAnswer.value; }
            if(this.textInputFaqCourseQuestion.value === '') { faqCourseQuestion = this.state.faq_course_question; } else { faqCourseQuestion = this.textInputFaqCourseQuestion.value; }
            if(this.textInputFaqCourseLanguage.value === '') { faqCourseLanguage = this.state.faq_course_language; } else { faqCourseLanguage = this.textInputFaqCourseLanguage.value; }
            if(this.textInputFaqCourseStatus.value === '') { faqCourseStatus = this.state.faq_course_status; } else { faqCourseStatus = this.textInputFaqCourseStatus.value; }
            faqCourseAnswer = ReplaceAllContent('"',"'",this.state.faq_course_answer);
            // faqCourseStatus = faqCourseStatus === 'Active' ? 1 : 0;
            
            var datatext ='{"faq_course_docid":"'+this.state.faq_course_id+'","faq_course_number":"'+faqCourseNumber+'","faq_course_answer":"'+faqCourseAnswer+'","faq_course_question":"'+faqCourseQuestion+'","faq_course_language":"'+faqCourseLanguage+'","faq_course_status":"'+faqCourseStatus+'"}';
            var urlFetch = global.config.urlLink;
            if(this.state.faq_course_id !== '') { urlFetch = urlFetch+"/faqcourseedit"; }
            else { urlFetch = urlFetch+"/faqcourseadd"; }
            //console.log(datatext);
            var data = JSON.parse(datatext); 
            
            fetch(urlFetch, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }, body : JSON.stringify(data)
            }).then(res => {
                if(res.status === 200) 
                    return res.json() 
            }).then( resJson => {
                document.getElementById('loading-area').style.display = 'none'; 
                if(resJson.status === '200') {
                    var param = faqCourseLanguage.toLowerCase(); if(faqCourseLanguage === "Indonesian") { param = "indonesia"; } 
                     window.location.href=global.config.urlCms+'/'+this.state.activelink+'/'+param;
                    // window.location.href=global.ctconfig.urlHost+'/ctcategory'+this.state.usage_for;
                } else {
                    console.log('Something happened wrong');
                }
            }).catch(err => err);

        } else {
            var arrfieldName = ['faq_course_number', 'faq_course_question', 'faq_course_answer', 'faq_course_language', 'faq_course_status'];
            var arrvalue = [this.textInputFaqCourseNumber.value, this.textInputFaqCourseQuestion.value, this.state.faq_course_answer, this.textInputFaqCourseLanguage.value, this.textInputFaqCourseStatus.value];
            for(var ck in arrfieldName) {
                this.validateField(arrfieldName[ck], arrvalue[ck]);
            }
        }
    }
    loadData(currpage,typelink,docid) {
        //var memberId = document.getElementById("contentid").innerText;
        if(typelink === 'edit' && docid !== "") {
            document.getElementById('loading-area').style.display = 'block'; 
            fetch(global.config.urlLink+"/faqcourseview/"+docid, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }
              }).then(res => {
                if(res.status === 200) 
                    return res.json() 
              }).then( resJson => {
                document.getElementById('loading-area').style.display = 'none'; 
                if(resJson.status === '200') { 
                    var resultContent = resJson.values[0].content[0].maincontent;
                    this.setState({
                        activelink: currpage,
                        stTitle: 'Edit FAQ ',
                        faq_course_id: resJson.values[0].docid,
                        faq_course_number: resultContent.faqCourseNumber,
                        faq_course_question: resultContent.faqCourseQuestion,
                        faq_course_answer: resultContent.faqCourseAnswer,
                        faq_course_language: resultContent.faqCourseLanguage,
                        faq_course_status: resultContent.faqCourseStatus,
                        formErrors: { faq_course_number: '', faq_course_question: '', faq_course_answer: '', faq_course_language: '', faq_course_status: ''},
                        faqcoursenumberValid: false,
                        faqcoursequestionValid: false,
                        faqcourseanswerValid: false,
                        faqcourselanguageValid: false,
                        faqcoursestatusValid: false,
                        formValid: false,
                        input_disabled: true
                    }); 
                } else {
                    console.log('Something happened wrong');
                }
              }).catch(err => { 
                document.getElementById('loading-area').style.display = 'none'; 
              });
        } else {
            document.getElementById('loading-area').style.display = 'none'; 
            var faqcrsLanguage = "";
            if(docid === "eng") { faqcrsLanguage = "English";  }
            else if(docid === "idn") { faqcrsLanguage = "Indonesian"; }
          
            this.setState( prevState => {
                return {
                  ...prevState.activelink, activelink: currpage,
                  ...prevState.stTitle, stTitle: GetText() ? "Tambah FAQ" : "Add FAQ",
                  ...prevState.faq_course_language, faq_course_language: faqcrsLanguage,
                  ...prevState.tab_language, tab_language: faqcrsLanguage,
                  ...prevState.faq_course_status, faq_course_status: 1,
                  
                }
            })
        }
    }

    componentDidMount() {
        var urlpath = window.location.pathname;
        if(urlpath.substring(urlpath.length-1,urlpath.length) === "/") {
          urlpath = urlpath.substring(0,urlpath.length-1);
        }
        var arrUrl = urlpath.split("/");
        
        var tabKey="", tabKey2="", docId="";
        if(arrUrl.length===4 && arrUrl[arrUrl.length-1] !== "") {
          tabKey = arrUrl[arrUrl.length-2];
          tabKey2 = arrUrl[arrUrl.length-1];
        } else if(arrUrl.length===5 && arrUrl[arrUrl.length-1] !== "") {
            tabKey = arrUrl[arrUrl.length-3];
            tabKey2 = arrUrl[arrUrl.length-2];
            docId = arrUrl[arrUrl.length-1];
        }
        if(tabKey === "") {
           
          this.setState({
              activelink: "pnf",
              stTitle : (tabKey2 === "add")? "Add " : (tabKey2 === "edit") ? "Edit " : "",
            });
        } else {
          this.loadData(tabKey,tabKey2,docId);
        }
        
      }
    render() {
        var optLanguage = [];
        if(this.state.tab_language === "English") {
            optLanguage.push(<option value="English" selected>English</option>)
        } else if(this.state.tab_language === "Indonesian") {
            optLanguage.push(<option value="Indonesian" selected>Indonesian</option>)
        } else {
            optLanguage.push(<option value="">-- please select --</option>)
            optLanguage.push(<option value="English">English</option>)
            optLanguage.push(<option value="Indonesian">Indonesian</option>)
        }
        return (
            <div id="setting">
                <Container>
                    { (this.state.activelink === "pnf")? 
                    <Error404 />
                    : 
                    (localStorage.getItem("ulvl") === global.config.userLevel[0] || localStorage.getItem("ulvl") === global.config.userLevel[2])? 
                    (<>
                        <div className="row py-5 mb-5">
                        {/* === start - body */}
                            <div className="col-md-3 bg-light">
                            { 
                                (this.state.activelink !== "none") && 
                                <MenuLeftCMS value={{tabkey:this.state.activelink}}/>
                            
                            }
                            </div>
                            <div className="col-md-9 p-4">
                            <div className="row">
                                <div>
                                <h3 className="mb-4">{GetText() ? " FAQ di Workshop" : " FAQ in Workshop"}</h3>
                                </div>
                                <div className="mt-3">
                                <div className="row show">
                                    <div className="col-6"><h5>{this.state.stTitle} </h5></div>
                                    <div className="col-6 d-flex justify-content-end">
                                        <Button variant="secondary" size="sm" onClick={this.handleBack.bind(this, this.state.activelink,this.state.faq_course_id)}>&#9664; back</Button>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                        <form onSubmit={this.handleSubmit}>
                                            <div >
                                            <input type="hidden" required name="faq_course_id" value={this.state.faq_course_id} ref={(input) => this.textInputFAQCourseId = input} />
                                            </div>
                                            <div className="row mt-3">
                                                <label className="col-md-3" htmlFor="course_title">Order of to</label>
                                                <div className="col-md-9">
                                                    <input type="number" className="form-control" name="faq_course_number"
                                                            placeholder={this.state.faq_course_number}
                                                            value={this.state.faq_course_number}
                                                            onChange={this.handleUserInput}  
                                                            ref={(input) => this.textInputFaqCourseNumber = input}
                                                            min="1"/> 
                                                    <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.faq_course_number}/>  }</div>
                                                </div>
                                            </div>
                                            
                                            <div className="row mt-3">
                                                <label className="col-md-3" htmlFor="faq_course_question">Question</label>
                                                <div className="col-md-9">
                                                    <textarea name="faq_course_question" className="form-control" 
                                                            rows={5} cols={30} 
                                                            value={this.state.faq_course_question} 
                                                            onChange={this.handleUserInput} 
                                                            ref={(input) => this.textInputFaqCourseQuestion = input}
                                                        />
                                                    <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.faq_course_question}/>  }</div>
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <label htmlFor="faq_course_answer">Answer</label>
                                                <div>
                                                    <CKEditor
                                                            editor  = { ClassicEditor }
                                                            name    = "faq_course_answer"
                                                            data    = {this.state.faq_course_answer}
                                                            config  = {{extraPlugins:[MyCustomUploadAdapterPlugin], state: this.state, key:authKey}}
                                                            onReady = { editor => {
                                                                // You can store the "editor" and use when it is needed.
                                                                editor.ui.view.editable.element.style.minHeight = "300px";
                                                            } }
                                                            onChange={ ( event, editor ) => {
                                                                this.handleUserCKEditorInput(editor);
                                                                const data = editor.getData();
                                                                
                                                                //this.textInputContentLongDesc = data;
                                                                editor.ui.view.editable.element.style.minHeight = "300px";
                                                            } }
                                                            onBlur={ ( event, editor ) => {
                                                                editor.ui.view.editable.element.style.minHeight = "300px";
                                                            } }
                                                            onFocus={ ( event, editor ) => {
                                                                editor.ui.view.editable.element.style.minHeight = "300px";
                                                            } }
                                                        />
                                                    <div className="mt-1">
                                                        { <FormErrors formErrors={this.state.formErrors.faq_course_answer}/>  }
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            <div className="row mt-3">
                                                <label className="col-md-3" htmlFor="faq_course_language">Language</label>
                                                <div className="col-md-9">
                                                    <select name="faq_course_language" className="form-control" 
                                                        value={this.state.faq_course_language} 
                                                        onChange={this.handleUserSelect}
                                                        ref={(input) => this.textInputFaqCourseLanguage = input}
                                                    >
                                                    {optLanguage}
                                                    </select>
                                                    <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.faq_course_language}/>  }</div>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <label className="col-md-3" htmlFor="faq_course_status">Status</label>
                                                <div className="col-md-9">
                                                    <select name="faq_course_status" className="form-control" 
                                                        value={this.state.faq_course_status} 
                                                        onChange={this.handleUserSelect}
                                                        ref={(input) => this.textInputFaqCourseStatus = input}
                                                    >
                                                        <option value="" key="">-- please select --</option>
                                                        <option value="1" key="status_active">Active</option>
                                                        <option value="0" key="status_inactive">Inactive</option>
                                                    </select>
                                                    <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.faq_course_status}/>  }</div>
                                                </div>
                                            </div>
                                            <div className="row mt-4 mb-3">
                                                <div className="col-md-3"></div>
                                                <div className="col-md-9"><Button type="submit" variant="secondary">Submit</Button></div>
                                            </div>
                                        </form>
                                </div>
                                </div>
                            </div>
                            </div>
                        {/* === end - body */}
                        </div>
                        
                        </>
                    )
                    : 
                    <div><CTNA/></div>
                    }
                    <div id="loading-area" className="d-none loading"></div> 
                    </Container>
            </div>
          );
        }
     }
      
export default CTFaqCourseForm;
