import React, { Component } from 'react';
import { Container, Button } from 'react-bootstrap';
import MenuLeftCMS from './ct-fragmentsidemenu';
import { GetText } from '../config/helper';
import '../config/global';
import CTNA from './ct-fragmentna';
import Error404 from '../Error404';
import DateTimeFormats from '../config/date-time-format';
import NumberFormats from '../config/number-format';

const authKey = global.config.kindcode.training[7]+global.config.authBearerKey;

export default class CTVoucherDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activelink:"none",
      stTitle: '',
      voucher_docid: '',
      voucher_createby: '',
      voucher_createdate: '',
      voucher_code: '',
      voucher_usage: '',
      voucher_start_date: '',
      voucher_end_date: '',
      voucher_domains:'',
      voucher_items: '',
      voucher_price_idr: 0,
      voucher_price_usd: 0,
      voucher_num: 0,
      voucher_stock: 0,
      voucher_status: '',
      voucher_quota: ''
    }
    this.loadDetailData = this.loadDetailData.bind(this);
  }
  handleBack = (currpage) => {
    // this.props.history.goBack();
    window.location.href=global.config.urlCms+"/"+currpage;
  }
  dataVoucherUsage = (dataVUsage) => {
    var vUsageList = "";
    if(dataVUsage !== undefined && dataVUsage.length > 0) {
      dataVUsage.forEach((list3) => {
        if(vUsageList !== "") { vUsageList += ", "; }
        vUsageList += list3;
      });
    }
    return (vUsageList);
  }
  listAllowsItems = (dataItems) => {
    var itemsList = [];
    if(dataItems !== undefined && dataItems.length > 0) {
      dataItems.forEach((list2) => {
        itemsList.push(
          <li><a href={global.config.urlHost+"/course/"+list2.value} target="_blank" rel="noreferrer">{list2.label}</a></li>
        ) 
      }) 
    }
    return [itemsList];
  }

  listAllowsDomains = (dataDomains) => {
    var domainsList = [];
    if(dataDomains !== undefined && dataDomains !== "") {
      dataDomains.split(",").forEach((list) => {
        domainsList.push(
          <li>{list}</li>
        ) 
      }) 
    }
    return [domainsList];
  }
  loadDetailData(currpage,typelink,docid) {
    if(docid !== '') { 
        document.getElementById('loading-area').style.display = 'block';
        fetch(global.config.urlLink+"/voucherview/"+docid, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': authKey,
            }
            }).then(res => {
            if(res.status === 200) 
                return res.json() 
            }).then( resJson => {
                document.getElementById('loading-area').style.display = 'none';
                if(resJson.status === '200') {
                if(resJson.values[0].content[0].maincontent !== undefined) {
                
                    var resultContent = resJson.values[0].content[0].maincontent;

                    var cDate="",sDate="",eDate="";
                    if(resultContent.createDate !== undefined) {
                        cDate = DateTimeFormats(resultContent.createDate._seconds,"datetime-short");
                    }
                    if(resultContent.startDate !== undefined) {
                      sDate = DateTimeFormats(resultContent.startDate._seconds,"datetime-short");
                    }
                    if(resultContent.endDate !== undefined) {
                      eDate = DateTimeFormats(resultContent.endDate._seconds,"datetime-short");
                    }
                    
                    this.setState({
                        activelink: currpage,
                        stTitle: GetText() ? "Detil Voucher" : "Detail Voucher",
                        voucher_docid: resJson.values[0].docid,
                        voucher_createby: resJson.values[0].content[0].maincontent.createByName,
                        voucher_createdate: cDate,
                        voucher_code: resultContent.voucherCode,
                        voucher_usage: resultContent.orderType,
                        voucher_start_date: sDate,
                        voucher_end_date: eDate,
                        voucher_domains: resultContent.allowDomain !== undefined? resultContent.allowDomain : "",
                        voucher_items: resultContent.allowItems !== undefined? resultContent.allowItems : [],
                        voucher_price_idr: resultContent.voucherAmountIDR,
                        voucher_price_usd: resultContent.voucherAmountUSD,
                        voucher_num: resultContent.voucherCount,
                        voucher_stock: resultContent.voucherStock,
                        voucher_status: resultContent.status,
                        voucher_quota: resultContent.voucherQuotaType,
                    }) 
                } else {
                    window.location.href=global.config.urlCms+"/"+currpage+"/add";
                }
                } else {
                console.log('Something wrong happened');
                }
            }).catch(err => err);
    } else {
        window.location.href=global.config.urlCms+"/"+currpage+"/add";
    }
   
  }
  componentDidMount() {
    var urlpath = window.location.pathname;
    if(urlpath.substring(urlpath.length-1,urlpath.length) === "/") {
      urlpath = urlpath.substring(0,urlpath.length-1);
    }
    var arrUrl = urlpath.split("/");
    
    var tabKey="", tabKey2="", docId="";
    if(arrUrl.length===4 && arrUrl[arrUrl.length-1] !== "") {
      tabKey = arrUrl[arrUrl.length-2];
      tabKey2 = arrUrl[arrUrl.length-1];
    } else if(arrUrl.length===5 && arrUrl[arrUrl.length-1] !== "") {
        tabKey = arrUrl[arrUrl.length-3];
        tabKey2 = arrUrl[arrUrl.length-2];
        docId = arrUrl[arrUrl.length-1];
    }
    if(tabKey === "") {
      this.setState({
          activelink: "pnf",
          stTitle : (tabKey2 === "add")? "Add " : (tabKey2 === "edit") ? "Edit " : ""
        });
    } else {
      this.loadDetailData(tabKey,tabKey2,docId);
    }
    
  }

  render() {

    return (
      <div className="setting">
       <Container>
        { (this.state.activelink === "pnf")? 
          <Error404 />
          : 
          (localStorage.getItem("ulvl") === global.config.userLevel[0] || localStorage.getItem("ulvl") === global.config.userLevel[2])? 
          (<>
            <div className="row py-5 mb-5">
              {/* === start - body */}
                <div className="col-md-3 bg-light">
                  { 
                    (this.state.activelink !== "none") && 
                      <MenuLeftCMS value={{tabkey:this.state.activelink}}/>
                  
                  }
                </div>
                <div className="col-md-9 p-4">
                  <div className="row">
                    <div>
                        <h3 className="mb-4">{GetText() ? " Voucher" : " Voucher"}</h3>
                    </div>
                    <div className="mt-3">
                      <div className="row show">
                          <div className="col-6">
                            <h5>{this.state.stTitle} </h5>
                          </div>
                          <div className="col-6 d-flex justify-content-end">
                             <Button variant="secondary" size="sm" onClick={this.handleBack.bind(this, this.state.activelink)}>&#9664; back</Button>
                          </div>
                      </div>
                      <div className="row">
                        <span className="text-muted">created by : {this.state.voucher_createby} <span className="ms-4">on : {this.state.voucher_createdate}</span></span>
                        <span className="text-muted">status : {this.state.voucher_status} </span>
                      </div>
                      <div className="row mt-3">
                            <div className="row mt-3">
                                <div className="col-md-3"><h6>Voucher Code</h6></div>
                                <div className="col-md-9">{this.state.voucher_code}</div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3"><h6>Voucher Amount</h6></div>
                                <div className="col-md-9">(IDR) {NumberFormats(this.state.voucher_price_idr)} / (USD) {NumberFormats(this.state.voucher_price_usd)} </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3"><h6>Used for</h6></div>
                                <div className="col-md-9">{this.dataVoucherUsage(this.state.voucher_usage)}</div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3"><h6>Active Date</h6></div>
                                <div className="col-md-9">{this.state.voucher_start_date} - {this.state.voucher_end_date}</div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3"><h6>Stock</h6></div>
                                <div className="col-md-9">{this.state.voucher_quota} 
                                {this.state.voucher_num > 0 && " ("+NumberFormats(this.state.voucher_stock) + " / " + NumberFormats(this.state.voucher_num) +")" }</div>
                            </div>
                          {this.state.voucher_domains !== "" && 
                            <div className="row mt-3">
                                <div className="col-md-12"><h6>Can be used for email account with domain </h6></div>
                                <div className="col-md-12"><ul>{this.listAllowsDomains(this.state.voucher_domains)}</ul></div>
                            </div>
                          }
                          {this.state.voucher_items.length > 0 && 
                            <div className="row mt-3">
                                <div className="col-md-12"><h6>Can be used for Workshop </h6></div>
                                <div className="col-md-12"><ul>{this.listAllowsItems(this.state.voucher_items)}</ul></div>
                            </div> 
                          }


                      </div>
                    </div>
                  </div>
                </div>
              {/* === end - body */}
            </div>
            
            </>
          )
        : 
          <div><CTNA/></div>
        }
        <div id="loading-area" className="d-none loading"></div> 
        </Container>
      </div>
    );
  }
}
