import React, { Component } from 'react';
import axios from 'axios';
import { Container, Button } from 'react-bootstrap';
import { FaTrash } from "react-icons/fa6";
// import DeleteIcon from '@mui/icons-material/Delete';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import MenuLeftCMS from './ct-fragmentsidemenu';
import { GetText } from '../config/helper';
import '../config/global';
import CTNA from './ct-fragmentna';
import { FormErrors } from '../inc/error-form';
import Error404 from '../Error404';
import ReplaceAllContent from '../config/replace-all';
import MyCustomUploadAdapterPlugin from '../inc/ckImageUploader';

const authKey = global.config.kindcode.training[1]+global.config.authBearerKey;
const authKey2 = global.config.kindcode.training[3]+global.config.authBearerKey;

function loadCategory() {
  return new Promise((resolve) => {
    var datatext ='{"keyword":"","pageShow":"","usageFor":"library"}';
    var data = JSON.parse(datatext); 
    fetch(global.config.urlLink+"/categorylist", {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey2,
        }, body : JSON.stringify(data)
    }).then(response2 => {
        if(response2.status === 200) {
            return response2.json()
        } else {
            console.log('Something happened wrong');
        }
    }).then(resJson2 => {
      resolve(resJson2);
    });
  })
}
function loadContent(docid) {
  return new Promise((resolve) => {
    fetch(global.config.urlLink+"/libraryview/"+docid, {
      method: 'GET',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': authKey,
      }
    }).then(res => {
      if(res.status === 200) 
          return res.json() 
    }).then( resJson => {
      resolve(resJson);
    })
  })
}
export default class CTLibraryForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activelink:"none",
      stTitle: '',
      tab_language: '',
      library_docid: '',
      library_title: '',
      library_short_desc: '',
      library_long_desc: '',
      library_language: '',
      library_picture: '',
      library_video: '',
      library_category: '',
      library_subcategory: '',
      source: 'library',
      categoryList: [],
      subcategoryList: [],
      library_status: '',
      selectedFileDocument: null,
      selectedFileDocument2: null,
      formErrors: {library_title: '', library_short_desc: '', library_long_desc: '', library_language: '', library_category: '', library_subcategory: '', library_status: '', selectedFileDocument:'', selectedFileDocument2:''},
      librarytitleValid: false,
      libraryshortdescValid: false,
      librarylongdescValid: false,
      librarylanguageValid: false,
      librarycategoryValid: false,
      librarysubcategoryValid: false,
      librarystatusValid: false,
      selectedFileDocumentValid: false,
      selectedFileDocument2Valid: false,
      formValid: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.submitData = this.submitData.bind(this);
    this.loadSubcategory = this.loadSubcategory.bind(this);
  }
  handleBack = (currpage) => {
    // this.props.history.goBack();
    window.location.href=global.config.urlCms+"/"+currpage;
  }
  handleUserCKEditorInput = (e) => {
    const name = "library_long_desc";
    const value = e.getData();
    this.setState({[name]: value},
        () => { this.validateField(name, value)});
  }
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value},
      () => { this.validateField(name, value)});
  }    
  handleUserSelect = (e) => {
    const inputname = e.target.name; 
    const value = e.target.value;
    var arrValues = value.split("$$");
    if(inputname === 'library_category') {
      this.loadSubcategory(arrValues[0]);
      this.setState( prevState => {
          return {  
              ...prevState.library_category, library_category: value
          }
        }, () => { this.validateField(inputname, value)
      }) 
    } else if(inputname === 'library_subcategory') {
      this.setState( prevState => {
          return {  
              ...prevState.library_subcategory , library_subcategory: value
          }
        }, () => { this.validateField(inputname, value)
      }) 
    } else {
      this.setState(prevState => {
          return {  
              ...prevState[inputname], [inputname]: value
          }
        }, () => { 
          this.validateField(inputname, value)
      });
      
    // } else if(inputname === 'library_language') {
    //     this.setState( prevState => {
    //         return {  
    //             ...prevState.library_language, library_language: value
    //         }
    //         }, () => { this.validateField(inputname, value)
    //     }) 
    // } else {
        
    }
  }
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let librarytitleValid = this.state.librarytitleValid;
    let libraryshortdescValid = this.state.libraryshortdescValid;
    let librarylongdescValid = this.state.librarylongdescValid;
    let librarylanguageValid = this.state.librarylanguageValid;
    let librarycategoryValid = this.state.librarycategoryValid;
    let librarysubcategoryValid = this.state.librarysubcategoryValid;
    let librarystatusValid = this.state.librarystatusValid;
    let selectedFileDocumentValid = this.state.selectedFileDocumentValid;
    let selectedFileDocument2Valid = this.state.selectedFileDocument2Valid;
    switch(fieldName) {
        case 'library_title': 
            librarytitleValid = value.trim().length > 0;
            fieldValidationErrors.library_title = librarytitleValid ? '' : 'title need to be filled';
            break;
        case 'library_short_desc': 
            libraryshortdescValid = value.trim().length > 0;
            fieldValidationErrors.library_short_desc = libraryshortdescValid ? '' : 'short description need to be filled';
            break;
        case 'library_long_desc': 
            librarylongdescValid = value.trim().length > 0;
            fieldValidationErrors.library_long_desc = librarylongdescValid ? '' : 'full description need to be filled';
            break;
        case 'library_language': 
            librarylanguageValid = value.trim().length > 0;
            fieldValidationErrors.library_language = librarylanguageValid ? '' : 'language need to be filled';
            break;
        case 'library_category': 
            librarycategoryValid = value.trim().length > 0;
            fieldValidationErrors.library_category = librarycategoryValid ? '' : 'category need to be filled';
            break;
        case 'library_subcategory': 
            librarysubcategoryValid = value.trim().length > 0;
            fieldValidationErrors.library_subcategory = librarysubcategoryValid ? '' : 'sub category need to be filled';
            break;
        case 'library_status': 
            librarystatusValid = value.trim().length > 0;
            fieldValidationErrors.library_status = librarystatusValid ? '' : 'status need to be filled';
            break;
        default:
            break;
    }
    this.setState({formErrors: fieldValidationErrors,
                    librarytitleValid: librarytitleValid,
                    libraryshortdescValid: libraryshortdescValid,
                    librarylongdescValid: librarylongdescValid,
                    librarylanguageValid: librarylanguageValid,
                    librarycategoryValid: librarycategoryValid,
                    librarysubcategoryValid: librarysubcategoryValid,
                    librarystatusValid: librarystatusValid,
                    selectedFileDocumentValid: selectedFileDocumentValid,
                    selectedFileDocument2Valid: selectedFileDocument2Valid
                }, this.validateForm);
  }
  validateForm() {
    this.setState({formValid: this.state.librarytitleValid && this.state.libraryshortdescValid && this.state.librarylongdescValid && this.state.librarylanguageValid && this.state.librarycategoryValid && this.state.librarysubcategoryValid && this.state.librarystatusValid && this.state.selectedFileDocumentValid && this.state.selectedFileDocument2Valid});
  }
  errorClass(error) {
    return(error.length === 0 ? '' : 'has-error');
  }
  onChangeFileHandler = (e) => {
    if(e.target.files[0] !== undefined) {
        var inputType = e.target.files[0].type;
        var inputValue = e.target.files[0];
        var name = ''; 
        var validname = '';
        let fieldValidationErrors = this.state.formErrors;
        let selectedFileValid = false;
        if(inputType.indexOf('pdf') > -1 || inputType.indexOf('video') > -1|| inputType.indexOf('image') > -1) { //add image
            selectedFileValid = true;
        } else {
            selectedFileValid = false;
        }
        if(e.target.id === "fileDocument2") {
            name =  'selectedFileDocument2';
            validname = 'selectedFileDocument2Valid';
            fieldValidationErrors.selectedFileDocument2 = selectedFileValid ? '' : 'please upload video only';
        } else {
            name =  'selectedFileDocument';
            validname = 'selectedFileDocumentValid';
            fieldValidationErrors.selectedFileDocument = selectedFileValid ? '' : 'please upload image only';
        }
        
        this.setState({
            [name]: inputValue,
            formErrors: fieldValidationErrors,
            [validname] : selectedFileValid
        }, this.validateForm);
    }
  }
  onDeleteFileHandler = (e) => {
    var name = ''; 
    //var validname = '';
    if(e.target.id === "deletePicture") {
        name =  'library_picture';
    } else {
        name =  'library_video';
    }
    
    this.setState({
        [name]: ''
    });
  }
  submitData(datasend,imgurl,vidurl) {
    var urlFetch, datatext;
    //var clientName,domainName,clientCountry,clientAddress,clientPhone,clientPICName,clientPICEmail;
    var libraryTitle,libraryShortDesc,libraryLongDesc,libraryLanguage,libraryPicture,libraryVideo,libraryCategory,librarySubCategory,libraryCategoryId,libraryCategoryName,librarySubCategoryId,librarySubCategoryName,libraryStatus;
    var arrCategory = [], arrSubCategory = [];
    if(imgurl !== '' && imgurl !== undefined) { libraryPicture = imgurl; } else { libraryPicture = this.state.library_picture; }
    if(vidurl !== '' && vidurl !== undefined) { libraryVideo = vidurl; } else { libraryVideo = this.state.library_video; }
    if(this.state.library_docid !== null && this.state.library_docid !== ''  && this.state.library_docid !== undefined) { 
        if(this.textInputLibraryTitle.value === '') { libraryTitle = this.state.library_title; } else { libraryTitle = this.textInputLibraryTitle.value; }
        if(this.textInputLibraryShortDesc.value === '') { libraryShortDesc = this.state.library_short_desc; } else { libraryShortDesc = this.textInputLibraryShortDesc.value; }
        // if(this.textInputLibraryLongDesc.value === '') { libraryLongDesc = this.state.library_long_desc; } else { libraryLongDesc = this.textInputLibraryLongDesc.value; }
        if(this.textInputLibraryLanguage.value === '') { libraryLanguage = this.state.library_language; } else { libraryLanguage = this.textInputLibraryLanguage.value; }
        if(this.textInputLibraryCategory.value === '') { libraryCategory = this.state.library_category; } else { libraryCategory = this.textInputLibraryCategory.value; }
        if(this.textInputLibrarySubcategory.value === '') { librarySubCategory = this.state.library_subcategory; } else { librarySubCategory = this.textInputLibrarySubcategory.value; }
        if(this.textInputLibraryStatus.value === '') { libraryStatus = this.state.library_status; } else { libraryStatus = this.textInputLibraryStatus.value; }
        // if(this.textInputLibraryFileDocId1.value === '') { libraryFiledocId1 = this.state.library_filedocId1; } else { libraryFiledocId1 = this.textInputLibraryFileDocId1.value; }
        // if(this.textInputLibraryFileDocId2.value === '') { libraryFiledocId2 = this.state.library_filedocId2; } else { libraryFiledocId2 = this.textInputLibraryFileDocId2.value; }
        // if(this.textInputLibraryFileDocId3.value === '') { libraryFiledocId3 = this.state.library_filedocId3; } else { libraryFiledocId3 = this.textInputLibraryFileDocId3.value; }
        // if(this.textInputLibraryFileDocId4.value === '') { libraryFiledocId4 = this.state.library_filedocId4; } else { libraryFiledocId4 = this.textInputLibraryFileDocId4.value; }
        // if(this.textInputLibraryFileDocId5.value === '') { libraryFiledocId5 = this.state.library_filedocId5; } else { libraryFiledocId5 = this.textInputLibraryFileDocId5.value; }
        
        libraryShortDesc = ReplaceAllContent('\n','<br/>',ReplaceAllContent(',','[;]',libraryShortDesc));
        libraryLongDesc = ReplaceAllContent('"',"'",ReplaceAllContent('\n','<br/>',ReplaceAllContent(',','[;]',this.state.library_long_desc)));

        arrCategory = libraryCategory.split("$$");
        if(arrCategory.length > 0) {
            libraryCategoryId=arrCategory[0];
            libraryCategoryName=arrCategory[1];
        }
        arrSubCategory = librarySubCategory.split("$$"); 
        if(arrSubCategory.length > 0) {
            librarySubCategoryId=arrSubCategory[0];
            librarySubCategoryName=arrSubCategory[1];
        }
        //datatext ='{"client_name":"'+clientName+'","domain_name":"'+domainName+'","client_country":"'+clientCountry+'","client_address":"'+clientAddress+'","client_phone":"'+clientPhone+'","client_pic_name":"'+clientPICName+'","client_pic_email":"'+clientPICEmail+'","client_docid":"'+this.state.clientdoc_id+'"}';
        //datatext ='{"library_title":"'+libraryTitle+'","library_short_desc":"'+libraryShortDesc+'","library_long_desc":"'+libraryLongDesc+'","library_category_id":"'+libraryCategoryId+'","library_category_name":"'+libraryCategoryName+'","library_subcategory_id":"'+librarySubCategoryId+'","library_subcategory_name":"'+librarySubCategoryName+'","library_filedocId1":"'+libraryFiledocId1+'","library_filedocId2":"'+libraryFiledocId2+'","library_filedocId3":"'+libraryFiledocId3+'","library_filedocId4":"'+libraryFiledocId4+'","library_filedocId5":"'+libraryFiledocId5+'","library_docid":"'+this.state.library_docid+'","form_id":"form-edit"}';
        datatext ='{"library_title":"'+libraryTitle+'","library_short_desc":"'+libraryShortDesc+'","library_long_desc":"'+libraryLongDesc+'","library_language":"'+libraryLanguage+'","library_picture":"'+libraryPicture+'","library_video":"'+libraryVideo+'","library_category_id":"'+libraryCategoryId+'","library_category_name":"'+libraryCategoryName+'","library_subcategory_id":"'+librarySubCategoryId+'","library_subcategory_name":"'+librarySubCategoryName+'","library_status":"'+libraryStatus+'","library_docid":"'+this.state.library_docid+'","form_id":"form-edit"}';
        urlFetch = global.config.urlLink+"/libraryedit";
    } else {
        libraryShortDesc = ReplaceAllContent('\n','<br/>',ReplaceAllContent(',','[;]',this.state.library_short_desc));
        libraryLongDesc = ReplaceAllContent('"',"'",ReplaceAllContent('\n','<br/>',ReplaceAllContent(',','[;]',this.state.library_long_desc)));
        
        arrCategory = this.state.library_category.split("$$");
        if(arrCategory.length > 0) {
            libraryCategoryId=arrCategory[0];
            libraryCategoryName=arrCategory[1];
        }
        arrSubCategory = this.state.library_subcategory.split("$$"); 
        if(arrSubCategory.length > 0) {
            librarySubCategoryId=arrSubCategory[0];
            librarySubCategoryName=arrSubCategory[1];
        }
        //datatext ='{"client_name":"'+this.state.client_name+'","domain_name":"'+this.state.domain_name+'","client_country":"'+this.state.client_country+'","client_address":"'+clientAddress+'","client_phone":"'+this.state.client_phone+'","client_pic_name":"'+this.state.client_pic_name+'","client_pic_email":"'+this.state.client_pic_email+'"}';
        //datatext ='{"library_title":"'+this.state.library_title+'","library_short_desc":"'+libraryShortDesc+'","library_long_desc":"'+libraryLongDesc+'","library_category_id":"'+libraryCategoryId+'","library_category_name":"'+libraryCategoryName+'","library_subcategory_id":"'+librarySubCategoryId+'","library_subcategory_name":"'+librarySubCategoryName+'","library_filedocId1":"'+this.state.library_filedocId1+'","library_filedocId2":"'+this.state.library_filedocId2+'","library_filedocId3":"'+this.state.library_filedocId3+'","library_filedocId4":"'+this.state.library_filedocId4+'","library_filedocId5":"'+this.state.library_filedocId5+'","form_id":"form-add"}';
        datatext ='{"library_title":"'+this.state.library_title+'","library_short_desc":"'+libraryShortDesc+'","library_long_desc":"'+libraryLongDesc+'","library_language":"'+this.state.library_language+'","library_picture":"'+libraryPicture+'","library_video":"'+libraryVideo+'","library_category_id":"'+libraryCategoryId+'","library_category_name":"'+libraryCategoryName+'","library_subcategory_id":"'+librarySubCategoryId+'","library_subcategory_name":"'+librarySubCategoryName+'","library_status":"'+libraryStatus+'","form_id":"form-add"}';
        urlFetch = global.config.urlLink+"/libraryadd";
    }
    var data = JSON.parse(datatext); 
    
    fetch(urlFetch, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
    }).then(res => {
        if(res.status === 200) 
            return res.json() 
    }).then( resJson => {
        if(resJson.status === '200') {
            var docId = resJson.values;
            
            window.location.href=global.config.urlCms+"/"+this.state.activelink+"/detailedit/"+docId;
            // window.location.href=global.config.urlHost+'/cteditdetaillibrary/'+docId;
        } else {
            console.log('Something happened wrong');
        }
    }).catch(err => { 
        document.getElementById('loading-area').style.display = 'none'; 
        console.log(err); 
    });
  }
  handleSubmit(e) {
    e.preventDefault();
    
    //if(this.state.client_name !== '' && this.state.domain_name !== '' && this.state.client_country !== '' && this.state.client_pic_name !== '' && this.state.client_pic_email !== '') {
    if(this.state.library_title !== '' && this.state.library_short_desc !== '' && this.state.library_long_desc !== '' && this.state.library_language !== '' && this.state.library_category !== '' && this.state.library_subcategory !== ''
        //&& this.state.selectedFileDocument !== '' && this.state.selectedFileDocument !== null
        ) {
        document.getElementById('loading-area').style.display = 'block'; 
        var selectedFileValue = this.state.selectedFileDocument;
        var selectedFileValue2 = this.state.selectedFileDocument2;
        if((selectedFileValue !== null && ((selectedFileValue.type.indexOf('pdf') > -1 || selectedFileValue.type.indexOf('video') > -1|| selectedFileValue.type.indexOf('image') > -1))) 
        || (selectedFileValue2 !== null && ((selectedFileValue2.type.indexOf('pdf') > -1 || selectedFileValue2.type.indexOf('video') > -1|| selectedFileValue2.type.indexOf('image') > -1))) 
        ) { 
            const data = new FormData() 
            data.append('file', selectedFileValue);
            data.append('file2', selectedFileValue2);
            data.append('docid', this.state.library_docid);
            //data.append('index', 1);
            data.append('source', 'library');
            //data.append('filetype',selectedFileValue.type);
            axios.post(global.config.urlLink+"/uploadfile", //axios.post(global.config.urlLink+"/uploadfile", 
            data, { 
                headers: { 
                    'content-type': 'multipart/form-data',
                    'Authorization': authKey
                }
            }).then(res => { 
                
                document.getElementById('loading-area').style.display = 'none'; 
                if(res.data.status === "200") {
                    this.submitData(this,res.data.values.linkfile1,res.data.values.linkfile2);
                } else {
                    document.getElementById('loading-area').style.display = 'none'; 
                    console.log("Something happened wrong");
                }
            /*
                "https://storage.googleapis.com/ps-product-internalportal-api.appspot.com/training/library/techno1.jpg"
            */
            }).catch(err => { 
                document.getElementById('loading-area').style.display = 'none'; 
                console.log(err); 
            })
        } else {
            document.getElementById('loading-area').style.display = 'none'; 
            this.submitData(this,"","");
                // var arrfieldName2 = ['selectedFileDocument'];
                // var arrvalue2 = [this.state.selectedFileDocument];
                // for(var ck2 in arrfieldName2) {
                //     this.validateField(arrfieldName2[ck2], arrvalue2[ck2]);
                // }
        }
    
    } else {
        //console.log(document.getElementById.bind("documentId1").value);
        var arrfieldName = ['library_title','library_short_desc','library_long_desc','library_language','library_category','library_subcategory','library_status','selectedFileDocument'];
        var arrvalue = [this.textInputLibraryTitle.value,this.textInputLibraryShortDesc.value,this.state.library_long_desc,this.textInputLibraryLanguage.value,this.textInputLibraryCategory.value,this.textInputLibrarySubcategory.value,this.textInputLibraryStatus.value,this.state.selectedFileDocument];
        for(var ck in arrfieldName) {
            this.validateField(arrfieldName[ck], arrvalue[ck]);
        }
    }
    
  }
  loadSubcategory(catid) {
    fetch(global.config.urlLink+"/categoryview/library/"+catid, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey2,
        }
    }).then(res => {
        if(res.status === 200) 
            return res.json() 
    }).then( resJson => {
        if(resJson.status === '200') { 
            this.setState( prevState => {
                return {  
                    ...prevState.subcategoryList, subcategoryList: resJson.values[0].scontent, 
                }
            }) 
        } else {
            console.log('Something happened wrong');
        }
    }).catch(err => err);
  }
  
  async loadData(currpage,typelink,docid) {
      if(typelink === 'edit') {
        document.getElementById('loading-area').style.display = 'block'; 
        var [result1, result2] = await Promise.all([loadContent(docid), loadCategory()]);
        if(result1 !== undefined && result1.status === '200') { 
          document.getElementById('loading-area').style.display = 'none'; 
          if(result1.values[0].content[0].maincontent !== undefined) {
              var lTitle="",lShortDesc="",lLongDesc="",lLanguage="",lPicture="",lVideo="",lCategory="",lSubCategory="",lStatus="";
              if(result1.values[0].content[0].maincontent.libraryTitle !== undefined) { lTitle = result1.values[0].content[0].maincontent.libraryTitle; }
              if(result1.values[0].content[0].maincontent.libraryShortDesc !== undefined) {
                lShortDesc = ReplaceAllContent('<br/>','\n',ReplaceAllContent('[;]',',',result1.values[0].content[0].maincontent.libraryShortDesc));
              }
              if(result1.values[0].content[0].maincontent.libraryLongDesc !== undefined) { 
                  lLongDesc = ReplaceAllContent('<br/>','\n',ReplaceAllContent('[;]',',',result1.values[0].content[0].maincontent.libraryLongDesc));
                  //lLongDesc = result1.values[0].content[0].maincontent.libraryLongDesc; 
              }
              if(result1.values[0].content[0].maincontent.libraryLanguage !== undefined) { 
                  lLanguage = result1.values[0].content[0].maincontent.libraryLanguage;
              }
              if(result1.values[0].content[0].maincontent.categoryId !== undefined) { 
                  lCategory = result1.values[0].content[0].maincontent.categoryId+"$$"+result1.values[0].content[0].maincontent.categoryName;
                  this.loadSubcategory(result1.values[0].content[0].maincontent.categoryId);
              }
              if(result1.values[0].content[0].maincontent.subcategoryId !== undefined) { 
                  lSubCategory = result1.values[0].content[0].maincontent.subcategoryId+"$$"+result1.values[0].content[0].maincontent.subcategoryName;
              }
              if(result1.values[0].content[0].maincontent.libraryPicture !== undefined) { 
                  lPicture = result1.values[0].content[0].maincontent.libraryPicture;
                  //lPicture = ReplaceAllContent('<br/>','\n',ReplaceAllContent('[;]',',',result1.values[0].content[0].maincontent.libraryPicture));
              }
              if(result1.values[0].content[0].maincontent.libraryVideo !== undefined) { 
                  lVideo = result1.values[0].content[0].maincontent.libraryVideo;
              }
              if(result1.values[0].content[0].maincontent.status !== undefined) { 
                lStatus = result1.values[0].content[0].maincontent.status;
            }
              this.setState({
                activelink: currpage,
                stTitle: 'Edit Library',
                library_docid: result1.values[0].docid,
                library_title: lTitle,
                library_short_desc: lShortDesc,
                library_long_desc: lLongDesc,
                library_language: lLanguage,
                library_picture: lPicture,
                library_video: lVideo,
                library_category: lCategory,
                library_subcategory: lSubCategory,
                library_status: lStatus,
                categoryList: result2,
                source: 'library',
                selectedFileDocument: null,
                selectedFileDocument2: null,
                formErrors: {library_title: '', library_short_desc: '', library_long_desc: '', library_language: '', library_category: '', library_subcategory: '', selectedFileDocument: '', selectedFileDocument2: ''},
                librarytitleValid: false,
                libraryshortdescValid: false,
                librarylongdescValid: false,
                librarylanguageValid: false,
                librarycategoryValid: false,
                librarysubcategoryValid: false,
                selectedFileDocumentValid: false,
                selectedFileDocument2Valid: false,
                formValid: false,
            }) 
          } else {
            window.location.href=global.config.urlCms+"/"+currpage+"/add";
          }
        } else {
          window.location.href=global.config.urlCms+"/"+currpage+"/add";
        }
      } else if(typelink === 'add') {
        document.getElementById('loading-area').style.display = 'block'; 
        var [result2b] = await Promise.all([loadCategory()]);
        if(result2b) {
          document.getElementById('loading-area').style.display = 'none';
          var libLanguage = "";
          if(docid === "eng") { libLanguage = "English"; }
          else if(docid === "idn") { libLanguage = "Indonesian"; }
           
          this.setState( prevState => {
              return {
                ...prevState.activelink, activelink: currpage,
                ...prevState.stTitle, stTitle: GetText() ? "Tambah Library" : "Add Library",
                ...prevState.categoryList, categoryList: result2b,
                ...prevState.library_language, library_language: libLanguage,
                ...prevState.tab_language, tab_language: libLanguage,
              }
          })
        }
    }  
    
  }
  componentDidMount() {
    var urlpath = window.location.pathname;
    if(urlpath.substring(urlpath.length-1,urlpath.length) === "/") {
      urlpath = urlpath.substring(0,urlpath.length-1);
    }
    var arrUrl = urlpath.split("/");
    
    var tabKey="", tabKey2="", docId="";
    if(arrUrl.length===4 && arrUrl[arrUrl.length-1] !== "") {
      tabKey = arrUrl[arrUrl.length-2];
      tabKey2 = arrUrl[arrUrl.length-1];
    } else if(arrUrl.length===5 && arrUrl[arrUrl.length-1] !== "") {
        tabKey = arrUrl[arrUrl.length-3];
        tabKey2 = arrUrl[arrUrl.length-2];
        docId = arrUrl[arrUrl.length-1];
    }
    if(tabKey === "") {
      this.setState({
          activelink: "pnf",
          stTitle : (tabKey2 === "add")? "Add " : (tabKey2 === "edit") ? "Edit " : ""
        });
    } else {
      this.loadData(tabKey,tabKey2,docId);
    }
    
  }

  render() {
    var selcategoryList = [];
    if(this.state.categoryList.values.length > 0) {
       for(var u in this.state.categoryList.values) {
        var selvalue = this.state.categoryList.values[u].docid+'$$'+this.state.categoryList.values[u].content.categoryName;
        selcategoryList.push(<option value={selvalue} key={u}>{this.state.categoryList.values[u].content.categoryName}</option>)
       }
    }

    var selsubcategoryList = [];
    if(this.state.subcategoryList.length > 0) {
        for(var v in this.state.subcategoryList) {
        var selvalue2 = this.state.subcategoryList[v].subdocid+'$$'+this.state.subcategoryList[v].subcontent.subcategoryName;
         selsubcategoryList.push(<option value={selvalue2} key={v}>{this.state.subcategoryList[v].subcontent.subcategoryName}</option>)
        }
     }
    var optLanguage = [];
    if(this.state.tab_language === "English") {
        optLanguage.push(<option value="English" selected>English</option>)
    } else if(this.state.tab_language === "Indonesian") {
        optLanguage.push(<option value="Indonesian" selected>Indonesian</option>)
    } else {
        optLanguage.push(<option value="">-- please select --</option>)
        optLanguage.push(<option value="English">English</option>)
        optLanguage.push(<option value="Indonesian">Indonesian</option>)
    }
    return (
      <div className="setting">
       <Container>
        { (this.state.activelink === "pnf")? 
          <Error404 />
          : 
          (localStorage.getItem("ulvl") === global.config.userLevel[0] || localStorage.getItem("ulvl") === global.config.userLevel[2])? 
          (<>
            <div className="row py-5 mb-5">
              {/* === start - body */}
                <div className="col-md-3 bg-light">
                  { 
                    (this.state.activelink !== "none") && 
                      <MenuLeftCMS value={{tabkey:this.state.activelink}}/>
                  
                  }
                </div>
                <div className="col-md-9 p-4">
                  <div className="row">
                    <div>
                      <h3 className="mb-4">{GetText() ? " Library" : " Library"}</h3>
                    </div>
                    <div className="mt-3">
                      <div className="row show">
                          <div className="col-6"><h5>{this.state.stTitle} </h5></div>
                          <div className="col-6 d-flex justify-content-end">
                             <Button variant="secondary" size="sm" onClick={this.handleBack.bind(this, this.state.activelink)}>&#9664; back</Button>
                          </div>
                      </div>
                      <div className="row mt-3">
                            <form onSubmit={this.handleSubmit}>
                                <div >
                                    <input type="hidden" required name="library_docid" value={this.state.library_docid} ref={(input) => this.textInputLibraryId = input}/>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="library_language">Language</label>
                                    <div className="col-md-9">
                                        <select name="library_language" className="form-control" 
                                            value={this.state.library_language} 
                                            onChange={this.handleUserSelect}
                                            ref={(input) => this.textInputLibraryLanguage = input}
                                            disabled={this.state.input_disabled}
                                        >
                                        {optLanguage}
                                        </select>
                                        <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.library_language}/>  }</div>
                                    </div>
                                </div>   
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="library_category">Category</label>
                                    <div className="col-md-9">
                                        <select name="library_category" className="form-control" 
                                            value={this.state.library_category} 
                                            onChange={this.handleUserSelect}
                                            ref={(input) => this.textInputLibraryCategory = input}
                                            disabled={this.state.input_disabled}
                                        >
                                        <option value="" key="">-- please select --</option>
                                        {selcategoryList}
                                        </select>
                                        <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.library_category}/>  }</div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="library_subcategory">Sub Category</label>
                                    <div className="col-md-9">
                                        <select name="library_subcategory" className="form-control" 
                                            value={this.state.library_subcategory} 
                                            onChange={this.handleUserSelect}
                                            ref={(input) => this.textInputLibrarySubcategory = input}
                                            disabled={this.state.input_disabled}
                                        >
                                        <option value="" key="">-- please select --</option>
                                        {selsubcategoryList}
                                        </select>
                                        <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.library_subcategory}/>  }</div>
                                    </div>
                                </div>
                                

                                <div className="row mt-5">
                                    <label className="col-md-3" htmlFor="library_title">Title</label>
                                    <div className="col-md-9">
                                        <input type="text" className="form-control" name="library_title"
                                            placeholder={this.state.library_title}
                                            value={this.state.library_title}
                                            onChange={this.handleUserInput}  
                                            ref={(input) => this.textInputLibraryTitle = input}/>
                                        <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.library_title}/>  }</div>
                                    </div>
                                </div>
                                
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="library_short_desc">Short Description</label>
                                    <div className="col-md-9">
                                        <textarea name="library_short_desc" className="form-control" 
                                                rows={5} cols={30} 
                                                /*placeholder={this.state.event_name}*/
                                                value={this.state.library_short_desc} 
                                                onChange={this.handleUserInput} 
                                                ref={(input) => this.textInputLibraryShortDesc = input}/>
                                        <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.library_short_desc}/>  }</div>
                                    </div>
                                </div>
                                {/*<div className="row-field mgT20">
                                    <label className="labelFormL" htmlFor="library_long_desc">Full Description</label>
                                    <div className="w70p">
                                        <textarea name="library_long_desc" className="form-control" 
                                                rows={5} cols={30} 
                                                value={this.state.library_long_desc} 
                                                onChange={this.handleUserInput} 
                                                ref={(input) => this.textInputLibraryLongDesc = input}/>
                                        <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.library_long_desc}/>  }</div>
                                    </div>
                                </div>*/}
                                <div className="row mt-3">
                                    <label className="labelFormL" htmlFor="library_long_desc">Full Description</label>
                                    <div>
                                        <CKEditor
                                            editor  = { ClassicEditor }
                                            name    = "library_long_desc"
                                            data    = {this.state.library_long_desc}
                                            config  = {{extraPlugins:[MyCustomUploadAdapterPlugin], state: this.state, key:authKey}}
                                            onReady = { editor => {
                                                // You can store the "editor" and use when it is needed.
                                                console.log( 'Editor is ready to use!', editor );
                                                editor.ui.view.editable.element.style.minHeight = "500px";
                                            } }
                                            onChange={ ( event, editor ) => {
                                                this.handleUserCKEditorInput(editor);
                                                const data = editor.getData();
                                                
                                                //this.textInputContentLongDesc = data;
                                                console.log( { event, editor, data } );
                                                editor.ui.view.editable.element.style.minHeight = "500px";
                                            } }
                                            onBlur={ ( event, editor ) => {
                                                console.log( 'Blur.', editor );
                                                editor.ui.view.editable.element.style.minHeight = "500px";
                                            } }
                                            onFocus={ ( event, editor ) => {
                                                console.log( 'Focus.', editor );
                                                editor.ui.view.editable.element.style.minHeight = "500px";
                                            } }
                                        />
                                        <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.library_long_desc}/>  }</div>
                                    </div>
                                </div>
                                
                                {(this.state.library_picture !== "" && this.state.library_picture !== undefined) && 
                                  <div className="row mt-3">
                                        <div className="col-md-3"></div>
                                        <div className="col-md-9 d-flex" >
                                          {/* display: flex; flex-direction: row; column-gap: 15px; align-items: flex-end; */}
                                            <img width="150" src={this.state.library_picture} alt=""/>
                                            <a className="linkText ms-2" href="#!" onClick={this.onDeleteFileHandler} id="deletePicture"><FaTrash/> Delete Picture &raquo;</a>
                                        </div>
                                  </div> 
                                }
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="library_document">Upload Picture</label>
                                    <div className="col-md-9">
                                        <input id="fileDocument" type="file"
                                            onChange={this.onChangeFileHandler}>
                                        </input> 
                                        <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.selectedFileDocument}/>  }</div>
                                        <div className="mt-2 fieldComments" >
                                        &bull; Please upload file in jpg, jpeg, png format.<br/>
                                        &bull; Dimension image min 350 px x 350 px with ratio 1 : 1
                                        </div>
                                    </div>
                                </div>
                                {(this.state.library_video !== "" && this.state.library_video !== undefined)?                                                                        
                                <div className="row mt-3">
                                    <div className="col-md-3"></div>
                                    <div className="col-md-9 d-flex">
                                      {/* display: flex; flex-direction: row; column-gap: 15px; align-items: flex-end; */}
                                        <iframe src={this.state.library_video} title={this.state.library_title}></iframe>
                                        <a className="linkText ms-2" href="#!" onClick={this.onDeleteFileHandler} id="deleteVideo"><FaTrash/> Delete Video &raquo;</a>
                                    </div>
                                </div>

                                : '' }
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="library_document2">Upload Video Intro</label>
                                    <div className="col-md-9">
                                        <input id="fileDocument2" type="file"
                                            onChange={this.onChangeFileHandler}>
                                        </input> 
                                        <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.selectedFileDocument2}/>  }</div>
                                        <div className="mt-2 fieldComments">
                                        &bull; Please upload file in mp4 format.
                                        </div>
                                    </div>
                                </div>


                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="library_status">Status</label>
                                    <div className="col-md-9">
                                        <select name="library_status" className="form-control" 
                                            value={this.state.library_status} 
                                            onChange={this.handleUserSelect}
                                            ref={(input) => this.textInputLibraryStatus = input}
                                            disabled={this.state.input_disabled}
                                        >
                                            <option value="" key="">-- please select --</option>
                                            <option value={global.config.libraryStatus[0]} key="status_0">{global.config.libraryStatus[0]}</option>
                                            <option value={global.config.libraryStatus[1]} key="status_1">{global.config.libraryStatus[1]}</option>
                                            <option value={global.config.libraryStatus[2]} key="status_2">{global.config.libraryStatus[2]}</option>
                                        </select>
                                        <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.library_status}/>  }</div>
                                    </div>
                                </div>
                                <div className="row mt-4 mb-3">
                                    <div className="col-md-3"></div>
                                    <div className="col-md-9"><Button type="submit" variant="secondary">Submit</Button></div>
                                </div>
                            </form>
                      </div>
                    </div>
                  </div>
                </div>
              {/* === end - body */}
            </div>
            
            </>
          )
        : 
          <div><CTNA/></div>
        }
        <div id="loading-area" className="d-none loading"></div> 
        </Container>
      </div>
    );
  }
}
