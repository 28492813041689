import React, { Component } from "react";
import Card from "react-bootstrap/Card";
import { FaRegCalendarDays } from 'react-icons/fa6';
import DateTimeFormats from "../../config/date-time-format";
import NAimage from '../../img/pic-na.png';

export default class Block extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: "",
			title: "",
			image: "",
			date: "",
			link: ""
		}
	}

	componentDidMount() {
		var data = this.props.value;
		this.setState({
			id: data.docid,
			title: data.content.courseTitle,
			image: (data.content.coursePicture !== "" && data.content.coursePicture !== undefined)? data.content.coursePicture : NAimage,
			date: DateTimeFormats(data.content.courseDate._seconds,"date-long"),
			link: '/course/' + data.docid
		});
	}

	render() {
		return (
			<a href={this.state.link}>
				<Card className="course-block">
					<Card.Img variant="top" src={this.state.image} alt={"Course " + this.state.title}/>
					<Card.Body className="text-center">
						<Card.Title>{this.state.title}</Card.Title>
						<Card.Text className="mt-2">
							<FaRegCalendarDays className="me-1"/>
							{this.state.date}
						</Card.Text>
					</Card.Body>
				</Card>
			</a>
		);
	}
}
