import React, { Component } from 'react';
import { Container, Button } from 'react-bootstrap';
import CreatableSelect from "react-select/creatable";
import DatePicker from 'react-datepicker';
import MenuLeftCMS from './ct-fragmentsidemenu';
import { GetText } from '../config/helper';
import '../config/global';
import CTNA from './ct-fragmentna';
import { FormErrors } from '../inc/error-form';
import Error404 from '../Error404';
import CheckBox from '../inc/checkbox';

const authKey = global.config.kindcode.training[7]+global.config.authBearerKey;
const authKey2 = global.config.kindcode.training[2]+global.config.authBearerKey;
const quota_type = global.config.voucherQuotaType;

export default class CTTutorForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activelink:"none",
      stTitle: '',
      voucher_docid: '',
      voucher_code: '',
      voucher_start_date: new Date(),
      voucher_end_date: new Date(),
      voucher_usage: [],      
      voucher_currency: [],
      voucher_price_idr: 0,
      voucher_price_usd: 0,
      voucher_quota: '',
      voucher_domains:[],
      voucher_items: [],
      voucher_num: 1,
      voucher_stock: 1,
      voucher_status: '',
      list_course: [],
      css_value_idr: 'd-none',
      css_value_usd: 'd-none',
      css_quota_num: 'd-none',
      formErrors: {voucher_code: '', voucher_active_date: '', voucher_usage: '', voucher_quota: '', voucher_num: '', voucher_currency: '', voucher_status: ''},
      vouchercodeValid0: false,
      vouchercodeValid5: false,
      voucherstartdateValid: false,
      voucherenddateValid: false,
      voucherusageValid: false,
      voucherquotaValid: false,
      vouchernumValid: false,
      voucherpriceidrValid: false,
      voucherpriceusdValid: false,
      voucherstatusValid: false,
      formValid: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.submitData = this.submitData.bind(this);
  }
  handleBack = (currpage) => {
    // this.props.history.goBack();
    window.location.href=global.config.urlCms+"/"+currpage;
  }
  setVoucherQuota(e) {
    var setStatez = {};
    var name = e.target.name;
    var value = e.target.value;
    if(value === quota_type[1]) {
      setStatez.css_quota_num = "d-flex";
    } else { setStatez.css_quota_num = "d-none"; }
    setStatez[name] = value;

    this.setState(setStatez, () => {
      this.validateField(name, value)
  });
  }
  handleInputChecked = (e) => {
    
    var setStatez = {};
    var name = e.target.id;
    var value = e.target.value;
    var stat = e.target.checked;
    var dataChecked = [];
    if(name === "voucher_usage") {
      dataChecked = this.state.voucher_usage;
    } else if(name === "voucher_currency") {
      dataChecked = this.state.voucher_currency;
      var display = "d-none"; if(stat === true) { display = "d-flex"; } 
      if(value === "IDR") { setStatez.css_value_idr = display; }
      else if(value === "USD") { setStatez.css_value_usd = display; }
    }

      var resChecked = [];
      if(dataChecked.length > 0) {
        var arrChecked = dataChecked;
        arrChecked.forEach((v_data,a) => {
          a++;
            if (v_data === value) {
              if(stat ===  true) { resChecked.push(value); throw new Error(); }
            } else {
              resChecked.push(v_data);
              if(arrChecked.length === a) {
                if(stat ===  true) { resChecked.push(value); }
              }
            }
            
        }) 
      } else { resChecked.push(value); }
      setStatez[name] = resChecked;

    this.setState(setStatez, () => {
        this.validateField(name, resChecked)
    });
     

    // this.setState({
    //     [name]: resChecked
    // }, () => {
    //     this.validateField(name, resChecked)
    // });
    
    
  }
  handleInputStartDate = date => {
    this.setState( prevState => {
        return {  
            ...prevState.voucher_start_date, voucher_start_date: date
        }
        }, () => { this.validateField('voucher_start_date', date)
    }) 
  }
  handleInputEndDate = date => {
    this.setState( prevState => {
        return {  
            ...prevState.voucher_end_date, voucher_end_date: date
        }
        }, () => { this.validateField('voucher_end_date', date)
    }) 
  }
  handleMultiSelect = (e) => {
    this.setState(prevState => {
        return {  
            ...prevState.voucher_domains, voucher_domains: e
        }
    }, () => { 
        this.validateField('voucher_domains', e)
    });
  }
  handleUserSelect = (e) => {
    this.setState(prevState => {
      return {  
          ...prevState.voucher_items, voucher_items: e
      }
  }, () => { 
      this.validateField('voucher_items', e)
  });
  }
  handleSingleSelect = (e) => {
    const name = e.target.name; 
    const value = e.target.value;
    this.setState(prevState => {
        return {  
            ...prevState[name], [name]: value
        }
    }, () => { 
        this.validateField(name, value)
    });
  }
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
        [name]: value
    }, () => {
        this.validateField(name, value)
    });
  }   

  
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let vouchercodeValid0 = this.state.vouchercodeValid0;
    let vouchercodeValid5 = this.state.vouchercodeValid5;
    let voucherstartdateValid = this.state.voucherstartdateValid;
    let voucherenddateValid = this.state.voucherenddateValid;
    let voucherusageValid = this.state.voucherusageValid;
    let voucherquotaValid = this.state.voucherquotaValid;
    let vouchernumValid = this.state.vouchernumValid;
    let voucherpriceidrValid = this.state.voucherpriceidrValid;
    let voucherpriceusdValid = this.state.voucherpriceusdValid;
    let voucherstatusValid = this.state.voucherstatusValid;
    switch(fieldName) {
        case 'voucher_code': 
            vouchercodeValid0 = value.trim().length > 0;
            vouchercodeValid5 = value.trim().length > 5;
            fieldValidationErrors.voucher_code = vouchercodeValid0 && vouchercodeValid5 ? '' : vouchercodeValid0? vouchercodeValid5? '' : 'voucher code must not less than 5' : 'voucher code need to be filled' ;
            break;
        case 'voucher_start_date': 
            voucherstartdateValid = value !== null;
            fieldValidationErrors.voucher_active_date = voucherstartdateValid ? '' : 'active periode need to be filled';
            break;
        case 'voucher_end_date': 
            voucherenddateValid = value !== null;
            fieldValidationErrors.voucher_active_date = voucherenddateValid ? '' : 'active periode need to be filled';
            break;
        case 'voucher_usage': 
            voucherusageValid = value.length > 0;
            fieldValidationErrors.voucher_usage = voucherusageValid ? '' : 'voucher usage need to be filled';
            break;
        case 'voucher_quota': 
            voucherquotaValid = value.trim().length > 0;
            fieldValidationErrors.voucher_quota = voucherquotaValid ? '' : 'quota voucher need to be filled';
            break;
        case 'voucher_num': 
            if(this.state.voucher_quota === quota_type[1]) {
              vouchernumValid = value > -1;
              fieldValidationErrors.voucher_num = vouchernumValid ? '' : 'qty voucher need to be filled';
            } 
            break;
        case 'voucher_currency': 
          if(value.length > 0) {
            if(value.includes("IDR")) {
              voucherpriceidrValid = this.state.voucher_price_idr > 0;
              fieldValidationErrors.voucher_currency = voucherpriceidrValid? '' : 'voucher value need to be filled';
            } 
            if(value.includes("USD")) {
              voucherpriceusdValid = this.state.voucher_price_usd > 0;
              fieldValidationErrors.voucher_currency = voucherpriceusdValid? '' : 'voucher value need to be filled';
            }
          } else {
            fieldValidationErrors.voucher_currency = 'voucher value need to be filled';
          }
          break;
        case 'voucher_price_idr': 
        case 'voucher_price_usd': 
        
            if(fieldName=== "voucher_price_idr") { voucherpriceidrValid = value > 0; }
            if(fieldName=== "voucher_price_usd") { voucherpriceusdValid = value > 0; }
            fieldValidationErrors.voucher_currency = (voucherpriceidrValid || voucherpriceusdValid) ? '' : 'voucher value need to be filled';
            break;
        case 'voucher_status': 
            voucherstatusValid = value.trim().length > 0;
            fieldValidationErrors.voucher_status = voucherstatusValid ? '' : 'status need to be filled';
            break;
        default:
            break;
    }    
    this.setState({
        formErrors: fieldValidationErrors,
        vouchercodeValid0: vouchercodeValid0,
        vouchercodeValid5: vouchercodeValid5,
        voucherstartdateValid: voucherstartdateValid,
        voucherenddateValid: voucherenddateValid,
        voucherusageValid: voucherusageValid,
        voucherquotaValid: voucherquotaValid,
        vouchernumValid: vouchernumValid,
        voucherpriceidrValid: voucherpriceidrValid,
        voucherpriceusdValid: voucherpriceusdValid,
        voucherstatusValid: voucherstatusValid
    }, this.validateForm);
  }
  vvalidateForm() {
    this.setState({
        formValid: this.state.vouchercodeValid && this.state.voucherstartdateValid && this.state.voucherenddateValid && this.state.voucherusageValid && this.state.voucherquotaValid && 
        this.state.vouchernumValid && this.state.voucherpriceidrValid && this.state.voucherpriceusdValid && this.state.voucherstatusValid 
    });
  }
  errorClass(error) {
    return(error.length === 0 ? '' : 'has-error');
  }
  
  
  handleSubmit(e) {
    e.preventDefault();   
    var voucherDomains = "";
    var voucherCode,voucherNum,voucherIDR,voucherUSD,voucherItems,voucherStatus;
    var vsDate = this.state.voucher_start_date;
    var veDate = this.state.voucher_end_date;
    var voucherUsage = this.state.voucher_usage; 
    var voucherQuota = this.state.voucher_quota;
    var voucherCurrency = this.state.voucher_currency;
    if(this.textInputVoucherCode.value === '') { voucherCode = this.state.voucher_code; } else { voucherCode = this.textInputVoucherCode.value; }
    if(this.textInputVoucherPriceIDR.value === '') { voucherIDR = this.state.voucher_price_idr; } else { voucherIDR = this.textInputVoucherPriceIDR.value; }
    if(this.textInputVoucherPriceUSD.value === '') { voucherUSD = this.state.voucher_price_usd; } else { voucherUSD = this.textInputVoucherPriceUSD.value; }
    // if(this.textInputVoucherDomains.value === '') { voucherDomains = this.state.voucher_domains; } else { voucherDomains = this.textInputVoucherDomains.value; }
    // if(this.textInputVoucherItems.value === '') { voucherItems = this.state.voucher_items; } else { voucherItems = this.textInputVoucherItems.value; }
    if(this.textInputVoucherStatus.value === '') { voucherStatus = this.state.voucher_status; } else { voucherStatus = this.textInputVoucherStatus.value; }    
    if(voucherQuota === quota_type[0]) { voucherNum = 0; }  
    else {
      if(this.textInputVoucherStock.value === '') { voucherNum = this.state.voucher_num; } else { voucherNum = this.textInputVoucherStock.value; }
      if(voucherNum !== "" & voucherNum !== undefined && voucherNum > 0) { }
      else { voucherNum = -1; }
    }  

    if(this.state.voucher_domains !== undefined && this.state.voucher_domains.length > 0) {
      this.state.voucher_domains.forEach((domain,c) => {
        if(voucherDomains !== "") { voucherDomains += ","; }
        voucherDomains += domain.value.trim();
          
      }) 
    }
    voucherItems = this.state.voucher_items;
    if(voucherCode !== '' && vsDate !== '' && veDate !== '' && voucherUsage !== '' && voucherCurrency !== '' && voucherQuota !== '' && voucherStatus !== '' && voucherNum > -1) {
      
        document.getElementById('loading-area').style.display = 'block'; 

        var urlFetch;
        var data = {
          voucher_code: voucherCode.toUpperCase(),
          voucher_usage: voucherUsage,
          voucher_start_date: vsDate,
          voucher_end_date: veDate,
          voucher_domains: voucherDomains,
          voucher_items: voucherItems,
          voucher_price_idr: voucherIDR,
          voucher_price_usd: voucherUSD,
          voucher_num: voucherNum,
          voucher_stock: voucherNum,
          voucher_status: voucherStatus,
          voucher_quota_type: voucherQuota
        }
        if(this.state.voucher_docid !== null && this.state.voucher_docid !== ''  && this.state.voucher_docid !== undefined) { 
          data.voucher_docid = this.state.voucher_docid;
          data.form_id = "form-edit";
          urlFetch = global.config.urlLink+"/voucheredit";
        } else {
          data.form_id = "form-add";
          urlFetch = global.config.urlLink+"/voucheradd";
        }
        fetch(urlFetch, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': authKey,
            }, body : JSON.stringify(data)
        }).then(res => {
            if(res.status === 200) 
                return res.json() 
        }).then( resJson => {
            if(resJson.status === '200') {
                var docId = resJson.values;
                window.location.href=global.config.urlCms+"/"+this.state.activelink+"/view/"+docId;
                // window.location.href=global.ctconfig.urlHost+'/cteditdetailtutor/'+docId;
            } else {
                console.log('Something happened wrong');
            }
    
            document.getElementById('loading-area').style.display = 'none'; 
            if(resJson.status === '200') {
            } else {
                console.log('Something happened wrong');
            }
        }).catch(err => { 
            document.getElementById('loading-area').style.display = 'none'; 
        });
        

    } else {
        var arrfieldName = ['voucher_code','voucher_start_date','voucher_end_date','voucher_usage','voucher_currency','voucher_quota','voucher_num','voucher_status'];
        var arrvalue = [voucherCode,vsDate,veDate,voucherUsage,voucherCurrency,voucherQuota,voucherNum,voucherStatus];
        for(var ck in arrfieldName) {
            this.validateField(arrfieldName[ck], arrvalue[ck]);
        }
    }
    
  }
  
  getListCourse = () => {
    return new Promise((resolve) => {
      var datatext ='{"keyword":"","pageShow":""}';
      var data = JSON.parse(datatext); 
      fetch(global.config.urlLink+"/courselist", {
          method: 'POST',
          //mode: 'no-cors', 
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey2,
          }, body : JSON.stringify(data)
      }).then(response => {
          if(response.status === 200) {
              return response.json()
          } else {
              console.log('Something happened wrong');
          }
      }).then(resJson => {
          var resultData = [];
          var resultList = resJson.values
          resultList.sort((a, b) => b.content.courseLanguage > a.content.courseLanguage);
          resultList.forEach((list,k) => {
            resultData.push({value: list.docid, label: "["+list.content.courseLanguage+"] " + list.content.courseTitle})
          }) 
          resolve(resultData)
      }).catch(err => {
          resolve([]);
      });
    });
  }
  getDetailVoucher = (docid) => {
    return new Promise((resolve) => {
      fetch(global.config.urlLink+"/voucherview/"+docid, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }
        }).then(res => {
        if(res.status === 200) 
            return res.json() 
        }).then( resJson => {
          if(resJson.status === '200') {
            resolve(resJson.values[0]);
          } else {
            console.log('Something wrong happened');
          }
        });
    })
  }
  async loadData(currpage,typelink,docid) {
    if(typelink === 'edit') {
        if(docid !== '') { 
            document.getElementById('loading-area').style.display = 'block';
            var [thing1, thing2] = await Promise.all([this.getDetailVoucher(docid), this.getListCourse()]);
            if(thing1 && thing2) {
              if(thing1.content[0].maincontent !== undefined) {
                    
                var resultContent = thing1.content[0].maincontent;
                var sDate="",eDate="";
                // if(thing1.content[0].createdate !== undefined) {
                //     cDate = DateTimeFormats(thing1.content[0].createdate._seconds,"datetime-short");
                // }
                if(thing1.content[0].maincontent.startDate !== undefined) {
                  sDate = new Date(thing1.content[0].maincontent.startDate._seconds * 1000);
                }
                if(thing1.content[0].maincontent.endDate !== undefined) {
                  eDate =  new Date(thing1.content[0].maincontent.endDate._seconds * 1000);
                }
                var vouchCurrency = [], cssIDR = "d-none", cssUSD = "d-none", cssQuota = "d-none";
                if(resultContent.voucherAmountIDR > 0) { vouchCurrency.push("IDR"); cssIDR="d-flex"; }
                if(resultContent.voucherAmountUSD > 0) { vouchCurrency.push("USD"); cssUSD="d-flex"; }
                if(resultContent.voucherQuotaType === quota_type[1]) { cssQuota = "d-flex"; }
              
                var arrVoucherDomains = []
                if(resultContent.allowDomain !== "") {
                  resultContent.allowDomain.split(",").forEach((list2) => {
                    arrVoucherDomains.push({value:list2,label:list2});
                  }) 
                }
                this.setState({
                    activelink: currpage,
                    stTitle: GetText() ? "Edit Voucher" : "Edit Voucher",
                    voucher_docid: thing1.docid,
                    voucher_code: resultContent.voucherCode,
                    voucher_start_date: sDate,
                    voucher_end_date: eDate,
                    voucher_usage: resultContent.orderType,      
                    voucher_currency: vouchCurrency,
                    voucher_price_idr: resultContent.voucherAmountIDR,
                    voucher_price_usd: resultContent.voucherAmountUSD,
                    voucher_quota: resultContent.voucherQuotaType,
                    voucher_domains: arrVoucherDomains,
                    voucher_items: (resultContent.allowItems !== undefined)? resultContent.allowItems : [],
                    voucher_num: resultContent.voucherCount,
                    // voucher_stock: 0,
                    voucher_status: resultContent.status,
                    css_value_idr: cssIDR,
                    css_value_usd: cssUSD,
                    css_quota_num: cssQuota,
                    list_course: thing2,
                    formErrors: {voucher_code: '', voucher_active_date: '', voucher_usage: '', voucher_quota: '', voucher_num: '', voucher_currency: '', voucher_status: ''},
                    vouchercodeValid0: false,
                    vouchercodeValid5: false,
                    voucherstartdateValid: false,
                    voucherenddateValid: false,
                    voucherusageValid: false,
                    voucherquotaValid: false,
                    vouchernumValid: false,
                    voucherpriceidrValid: false,
                    voucherpriceusdValid: false,
                    voucherstatusValid: false,
                    formValid: false,
                }) 
              } else {
                window.location.href=global.config.urlCms+"/"+currpage+"/add";
              }
            }
        } else {
          var [thing2b] = await Promise.all([this.getListCourse()]);
          if(thing2b) {
            this.setState( prevState => {
              return {
                ...prevState.activelink, activelink: currpage,
                ...prevState.stTitle, stTitle:  GetText() ? "Edit Voucher" : "Edit Voucher",
                ...prevState.list_course, list_course: thing2b,
              }
            })  
          }
        }
    } else if(typelink === 'add') {
        var [thing2c] = await Promise.all([this.getListCourse()]);
        if(thing2c) {
          this.setState( prevState => {
              return {
                ...prevState.activelink, activelink: currpage,
                ...prevState.stTitle, stTitle:  GetText() ? "Tambah Voucher" : "Add Voucher",
                ...prevState.list_course, list_course: thing2c,
              }
          })
        }
    }    
  }
  componentDidMount() {
    var urlpath = window.location.pathname;
    if(urlpath.substring(urlpath.length-1,urlpath.length) === "/") {
      urlpath = urlpath.substring(0,urlpath.length-1);
    }
    var arrUrl = urlpath.split("/");
    
    var tabKey="", tabKey2="", docId="";
    if(arrUrl.length===4 && arrUrl[arrUrl.length-1] !== "") {
      tabKey = arrUrl[arrUrl.length-2];
      tabKey2 = arrUrl[arrUrl.length-1];
    } else if(arrUrl.length===5 && arrUrl[arrUrl.length-1] !== "") {
        tabKey = arrUrl[arrUrl.length-3];
        tabKey2 = arrUrl[arrUrl.length-2];
        docId = arrUrl[arrUrl.length-1];
    }
    if(tabKey === "") {
      this.setState({
          activelink: "pnf",
          stTitle : (tabKey2 === "add")? "Add " : (tabKey2 === "edit") ? "Edit " : ""
        });
    } else {
      this.loadData(tabKey,tabKey2,docId);
    }
    
  }

  render() {
    return (
      <div className="setting">
       <Container>
        { (this.state.activelink === "pnf")? 
          <Error404 />
          : 
          (localStorage.getItem("ulvl") === global.config.userLevel[0] || localStorage.getItem("ulvl") === global.config.userLevel[2])? 
          (<>
            <div className="row py-5 mb-5">
              {/* === start - body */}
                <div className="col-md-3 bg-light">
                  { 
                    (this.state.activelink !== "none") && 
                      <MenuLeftCMS value={{tabkey:this.state.activelink}}/>
                  
                  }
                </div>
                <div className="col-md-9 p-4">
                  <div className="row">
                    <div>
                      <h3 className="mb-4">{GetText() ? " Voucher" : " Voucher"}</h3>
                    </div>
                    <div className="mt-3">
                      <div className="row show">
                          <div className="col-6"><h5>{this.state.stTitle} </h5></div>
                          <div className="col-6 d-flex justify-content-end">
                             <Button variant="secondary" size="sm" onClick={this.handleBack.bind(this, this.state.activelink)}>&#9664; back</Button>
                          </div>
                      </div>
                      <div className="row mt-3">
                            <form onSubmit={this.handleSubmit}>
                                <div >
                                    <input type="hidden" required name="voucher_docid" value={this.state.voucher_docid}/>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="voucher_code">Voucher Code</label>
                                    <div className="col-md-9">
                                        <input type="text" className="form-control" name="voucher_code"
                                                placeholder={this.state.voucher_code}
                                                value={this.state.voucher_code}
                                                onChange={this.handleUserInput}  
                                                ref={(input) => this.textInputVoucherCode = input}/>
                                        <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.voucher_code}/>  }</div>
                                        <div className="mt-2 fieldComments">
                                          &bull; Code must not less than 5 characters
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="voucher_active_date">Active Date</label>
                                    <div className="col-md-9">
                                      <div className="d-flex">
                                              <DatePicker
                                                name="voucher_start_date"
                                                onChange={this.handleInputStartDate}
                                                value={this.state.voucher_start_date}
                                                selected={this.state.voucher_start_date}
                                                dateFormat="MMMM d, yyyy"
                                                //style={{border:'none'}}
                                                // customStyles={{dateInput:{borderWidth: 0}}}
                                                className="form-control"
                                                />
                                              <span style={{width:"50px"}} className="d-flex align-items-center justify-content-center"> s/d </span>
                                              <DatePicker
                                                name="voucher_end_date"
                                                onChange={this.handleInputEndDate}
                                                value={this.state.voucher_end_date}
                                                selected={this.state.voucher_end_date}
                                                dateFormat="MMMM d, yyyy"
                                                //style={{border:'none'}}
                                                // customStyles={{dateInput:{borderWidth: 0}}}
                                                className="form-control"
                                              />
                                      
                                      </div>
                                        <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.voucher_active_date}/>  }</div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-md-3">Used For</label>
                                    <div className="col-md-9">
                                      <div className="d-flex">
                                      {
                                        global.config.orderItemType.map((item2, index) => {
                                            return (
                                            <CheckBox  
                                              fieldId={"voucher_usage"} 
                                              id={"A"+index} 
                                              value={item2}
                                              isChecked={this.state.voucher_usage.includes(item2)}
                                              // isDisable={false}
                                              handleInputChecked={this.handleInputChecked}  label={item2} />)
                                        })
                                      }
                                      </div>
                                       <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.voucher_usage}/>  }</div>
                                    </div>
                                </div>
                                
                                <div className="row mt-3">
                                    <label className="col-md-3">Quota Voucher</label>
                                    <div className="col-md-9">
                                      <div className="d-flex"> 
                                        <div className="form-check me-3">
                                          <input className="form-check-input" type="radio" 
                                                 name="voucher_quota" id="voucher_quota1" 
                                                 value={quota_type[0]} 
                                                 onChange={(e) => this.setVoucherQuota(e)} 
                                                 checked={this.state.voucher_quota === quota_type[0]}
                                            />
                                          <label className="form-check-label" htmlFor="voucher_quota1" style={{marginLeft:"5px"}}>
                                            {quota_type[0]}
                                          </label>
                                        </div>
                                      </div>
                                      <div className="d-flex ">
                                        <div className="col-md-4 d-flex align-items-center"  style={{height:"40px"}}>
                                          <div className="form-check " >
                                            <input className="form-check-input" type="radio" 
                                                   name="voucher_quota" id="voucher_quota2" 
                                                   value={quota_type[1]} 
                                                   onChange={(e) => this.setVoucherQuota(e)} 
                                                   checked={this.state.voucher_quota === quota_type[1]}
                                              />
                                            <label className="form-check-label" htmlFor="voucher_quota2" style={{marginLeft:"5px"}}>
                                              {quota_type[1]}
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className={"input-group "+this.state.css_quota_num} style={{width:"200px"}}>
                                              <input type="number" className="form-control" name="voucher_num"
                                                        placeholder={this.state.voucher_num}
                                                        value={this.state.voucher_num}
                                                        onChange={this.handleUserInput}  
                                                        ref={(input) => this.textInputVoucherStock = input}
                                                        // min="1"
                                                        /> 
                                              <span className="input-group-text">pcs</span>
                                              
                                            </div>
                                            <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.voucher_num}/>  }</div>
                                        </div>
                                      </div>
                                       <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.voucher_quota}/>  }</div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-md-3">Applies To Currency</label>
                                    <div className="col-md-9">
                                      <div className="d-flex mb-2">
                                        <div className="col-md-3 d-flex align-items-center" style={{height:"40px"}}>
                                          <CheckBox  
                                              fieldId={"voucher_currency"} 
                                              id={"B1"} 
                                              value={"IDR"}
                                              isChecked={this.state.voucher_currency.includes("IDR")}
                                              // isDisable={false}
                                              handleInputChecked={this.handleInputChecked}  label={"IDR"} />
                                        </div>
                                        <div className="col-md-9">
                                          <div className={"input-group me-3 "+this.state.css_value_idr} id="value_idr">
                                            <span className="input-group-text" id="voucher_value_idr">IDR</span>
                                            <input type="number" className="form-control" name="voucher_price_idr"
                                                      placeholder={this.state.voucher_price_idr}
                                                      value={this.state.voucher_price_idr}
                                                      onChange={this.handleUserInput}  
                                                      ref={(input) => this.textInputVoucherPriceIDR = input}
                                                      min="0"/> 
                                          </div>
                                        </div>
                                      </div>
                                      <div className="d-flex">
                                        <div className="col-md-3 d-flex align-items-center" style={{height:"40px"}}>
                                          <CheckBox  
                                              fieldId={"voucher_currency"} 
                                              id={"B2"} 
                                              value={"USD"}
                                              isChecked={this.state.voucher_currency.includes("USD")}
                                              // isDisable={false}
                                              handleInputChecked={this.handleInputChecked}  label={"USD"} />
                                        </div>
                                        <div className="col-md-9">
                                          <div className={"input-group me-3 "+this.state.css_value_usd} id="value_usd">
                                            <span className="input-group-text" id="voucher_price_usd">USD</span>
                                            <input type="number" className="form-control" name="voucher_price_usd"
                                                      placeholder={this.state.voucher_price_usd}
                                                      value={this.state.voucher_price_usd}
                                                      onChange={this.handleUserInput}  
                                                      ref={(input) => this.textInputVoucherPriceUSD = input}
                                                      min="0"/> 
                                          </div>
                                        </div>
                                      </div>
                                       <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.voucher_currency}/>  }</div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="voucher_domains">Applies to Selected Domains</label>
                                    <div className="col-md-9">
                                      <CreatableSelect className="select-multi" 
                                          // options={[]} 
                                          value={this.state.voucher_domains} 
                                          placeholder="type domain name" 
                                          onChange={(values) => this.handleMultiSelect(values)}
                                          ref={(input) => this.textInputVoucherDomains = input} 
                                          isMulti/>

                                      {/*<Select  name="voucher_domains" 
                                        isMulti
                                        value={this.state.voucher_domains} 
                                        onChange={this.handleUserSelect}
                                      />
                                    */}
                                        {/* <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.voucher_domains}/>  }</div> */}
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="voucher_items">Applies to Selected Workshops</label>
                                    <div className="col-md-9">
                                      <CreatableSelect
                                        options={this.state.list_course} 
                                        value={this.state.voucher_items} 
                                        placeholder="Select items" 
                                        onChange={(values) => this.handleUserSelect(values)} 
                                        ref={(input) => this.textInputVoucherItems = input}
                                        isMulti/>

                                      {/*<Select  name="voucher_domains" 
                                        isMulti
                                        value={this.state.voucher_domains} 
                                        onChange={this.handleUserSelect}
                                      />
                                    */}
                                        {/* <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.voucher_items}/>  }</div> */}
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="voucher_status">Status</label>
                                    <div className="col-md-9">
                                        <select name="voucher_status" className="form-control" 
                                            value={this.state.voucher_status} 
                                            onChange={this.handleSingleSelect}
                                            ref={(input) => this.textInputVoucherStatus = input}
                                            
                                        >
                                            <option value="" key="">-- please select --</option>
                                            { 
                                              global.config.voucherStatus.map((item, j) => {
                                                return (
                                                  <option value={item} key={"status_"+item}>{item}</option>
                                                )
                                              })
                                            }
                                        </select>
                                        <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.voucher_status}/>  }</div>
                                    </div>
                                </div>
                                <div className="row mt-4 mb-3">
                                    <div className="col-md-3"></div>
                                    <div className="col-md-9"><Button type="submit" variant="secondary">Submit</Button></div>
                                </div>
                            </form>
                      </div>
                    </div>
                  </div>
                </div>
              {/* === end - body */}
            </div>
            
            </>
          )
        : 
          <div><CTNA/></div>
        }
        <div id="loading-area" className="d-none loading"></div> 
        </Container>
      </div>
    );
  }
}
