import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';

export default class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      divStyles: {},
      componentStyles: {},
      ischildLoad: false
    }
  }

  componentDidMount() {
    var content = this.props.value;
    var divstyle = content.divstyle? content.divstyle : {};
    var componentstyle = content.componentstyle? content.componentstyle : {};
    if(content.type !== undefined && content.type === 'screen') {
      divstyle.marginTop='100px';
      divstyle.marginBottom='250px';
      componentstyle.width='4rem';
      componentstyle.height='4rem';
    } else {
      divstyle.marginTop='20px';
      divstyle.marginBottom='20px';
    }
    this.setState({
      id: content.id,
      type: content.type? content.type: '',
      divStyles: divstyle,
      componentStyles: componentstyle,
      ischildLoad: true,
    });
  }

  render() {
    return (
      
          <div 
            id={this.state.id}
            className="w-full d-flex justify-content-center " 
            style={this.state.divStyles}
            >
            <Spinner animation="border" variant="secondary" style={this.state.componentStyles}/>
          </div>
      
    );
  }
}