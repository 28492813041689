import React, { Component } from 'react';
import { Container, Button } from 'react-bootstrap';
import { FaPlus, FaPen, FaFileLines, FaToggleOn, FaToggleOff } from "react-icons/fa6";
import { GetText } from '../config/helper';
import NumberFormats from '../config/number-format';
import DateTimeFormats from '../config/date-time-format';
import '../config/global';
import MenuLeftCMS from './ct-fragmentsidemenu';
import CTNA from './ct-fragmentna';

const authKey = global.config.kindcode.training[7]+global.config.authBearerKey;

export default class CTVoucher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activelink:"none",
      pageShow: '10',
      keyword: '',
      data: []
    }
    this.loadData = this.loadData.bind(this);
  }

  urladdVoucher(currpage) {
    window.location.href=global.config.urlCms+"/"+currpage+'/add';
  }
  handlePublish(valId,nextstat) {
    document.getElementById('loading-area').style.display = 'block';
    // var datatext ='{"feedback_text":"'+content.feedbackText+'","feedback_number":"'+content.feedbackNumber+'","feedback_type":"'+content.feedbackType+'","feedback_status":"'+nextstat+'","option1":"'+content.option1+'","option2":"'+content.option2+'","option3":"'+content.option3+'","option4":"'+content.option4+'","option5":"'+content.option5+'","feedback_docid":"'+valId+'","form_id":"form-edit"}';
    var datatext ='{"voucher_status":"'+nextstat+'","voucher_docid":"'+valId+'","form_id":"form-publish"}';
    var urlFetch = global.config.urlLink+"/voucheredit";
    var data = JSON.parse(datatext);
    fetch(urlFetch, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
    }).then(res => {
        if(res.status === 200)
            return res.json() 
    }).then( resJson => {
        if(resJson.status === '200') {
            // var docId = resJson.values;
            window.location.href=global.config.urlCms+"/"+this.state.activelink;
        } else {
            console.log('Something happened wrong');
        }
        document.getElementById('loading-area').style.display = 'none'; 
        if(resJson.status === '200') {
        } else {
            console.log('Something happened wrong');
        }
    }).catch(err => { 
        document.getElementById('loading-area').style.display = 'none'; 
    });
  }
  dataVoucherUsage = (dataVUsage) => {
    var vUsageList = "";
    if(dataVUsage !== undefined && dataVUsage.length > 0) {
      dataVUsage.forEach((list3) => {
        if(vUsageList !== "") { vUsageList += ", "; }
        vUsageList += list3;
      });
    }
    return (vUsageList);
  }
  
  listVoucher = (dataVoucher) => {
    var voucherList = [];
    if(dataVoucher.length > 0) {
      dataVoucher.sort((a, b) => a.content.startDate._seconds > b.content.startDate._seconds);
      for(var ls=0; ls < dataVoucher.length; ls++) {
          var retResult = dataVoucher[ls];
          var editURL = global.config.urlCms+"/"+this.state.activelink+"/edit/"+retResult.docid;
          var viewURL = global.config.urlCms+"/"+this.state.activelink+"/view/"+retResult.docid;
          var iconPub = <FaToggleOff style={{color:"#999999"}} className="styleicons" onClick={this.handlePublish.bind(this, retResult.docid, global.config.voucherStatus[0])}/>;
          if(retResult.content.status !== undefined && retResult.content.status === global.config.voucherStatus[0]) { iconPub =  <FaToggleOn className="styleicons" onClick={this.handlePublish.bind(this, retResult.docid, global.config.voucherStatus[1])}/> }

          voucherList.push(<tr key={retResult.docid}>
              <th scope="row-field">{ls+1}</th>
              <td>{retResult.content.voucherCode}</td>
              <td>{this.dataVoucherUsage(retResult.content.orderType)}</td>
              <td>
                {DateTimeFormats(retResult.content.startDate._seconds,"date-short")} - {DateTimeFormats(retResult.content.endDate._seconds,"date-short")}
              </td>
              <td>
                (IDR) {NumberFormats(retResult.content.voucherAmountIDR)}<br/>
                (USD) {NumberFormats(retResult.content.voucherAmountUSD)}
              </td>
              <td>
                <a href={editURL}><FaPen className="styleicons" title="edit" /></a>
                <a href={viewURL}><FaFileLines className="styleicons" title="view" /></a>
                {iconPub}
              </td>
            </tr>)
      }
    } else {
      voucherList.push(<tr key="1">
          <th scope="row"></th>
          <td colspan={2} className="d-flex p-3">- no data -</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>)
    }
    return [voucherList];
  }
  loadData() {
    document.getElementById('loading-area').style.display = 'block'; 
    var datatext ='{"keyword":"'+this.state.keyword+'","pageShow":"'+this.state.pageShow+'"}';
    var data = JSON.parse(datatext); 
    fetch(global.config.urlLink+"/voucherlist", {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
    }).then(response => {
        if(response.status === 200) {
            return response.json()
        } else {
            console.log('Something happened wrong');
        }
    }).then(resJson => {
        document.getElementById('loading-area').style.display = 'none'; 
        this.setState({
            data: resJson
        })
    }).catch(err => {
        document.getElementById('loading-area').style.display = 'none'; 
    });
  }

  componentDidMount() {
    var url = window.location.href;
    var arrUrl = url.split("/");
    // var tabKey = url.substring(url.lastIndexOf('/') + 1);
    var tabKey = arrUrl[arrUrl.length-1];
    if(tabKey === "") {
      tabKey = arrUrl[arrUrl.length-2];
    }
    this.setState({
      activelink: tabKey
    });
    this.loadData();
  }

  render() {
    
    return (
      <div className="setting">
       <Container>
        {
          (localStorage.getItem("ulvl") === global.config.userLevel[0] || localStorage.getItem("ulvl") === global.config.userLevel[2])? 
          (<>
            <div className="row py-5 mb-5">
              {/* === start - body */}
                <div className="col-md-3 bg-light">
                  { 
                    this.state.activelink !== "none" && 
                      <MenuLeftCMS value={{tabkey:this.state.activelink}}/>
                  
                  }
                </div>
                <div className="col-md-9 p-4">
                  <div className="row">
                    <div>
                      <h3>{GetText() ? "Voucher" : "Voucher"}</h3>
                      <span className="text-muted">{GetText() ? "Daftar Voucher untuk transaksi" : "List of Voucher for Transaction"}</span>
                    </div>
                    <div className="mt-3">
                      <div className="row show">
                          <div className="col-6"></div>
                          <div className="col-6 d-flex justify-content-end">
                              {this.state.activelink !== "" && <Button className="mt-4" variant="secondary" size="sm" onClick={this.urladdVoucher.bind(this, this.state.activelink)}><FaPlus style={{color:"#FFFFFF", size: "20px"}} className="me-2" />Add New Voucher</Button> }
                          </div>
                      </div>
                      <div className="mt-3">
                          <table className="table">
                            <thead className="table-dark">
                              <tr key="0">
                              <th width="50" className="text-center" scope="col">#</th>
                              <th scope="col" width="150">Code</th>
                              <th scope="col" width="150">Type</th>
                              <th scope="col">Active Period</th>
                              <th scope="col" width="150">Price</th>
                              <th width="150" scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                                {this.listVoucher(this.state.data.values)}
                            </tbody>
                          </table>
                      </div>
                    </div>
                  </div>
                </div>
              {/* === end - body */}
            </div>
            
            </>
          )
        : 
          <div><CTNA/></div>
        }
        <div id="loading-area" className="d-none loading"></div> 
        </Container>
      </div>
    );
  }
}
